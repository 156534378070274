import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InputLabel, Button } from '@material-ui/core';
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { cartActions, cartSelectors } from 'app/cart';
import { appSelectors } from 'app/redux';
import { TransactionStatus } from 'common/cart';
import { Spinner } from 'common/statusIndicators';
import APP_CONFIG from 'config/app';
import * as NAVIGATION from 'config/navigation';
import * as API from 'service/api';
import { useEffectAsync, getErrorMessage, loadDynamicScript } from 'service/utility';


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};


const CreditCardForm = (props) => {
  const history = useHistory();

  const [merchant, setMerchant] = React.useState(null);
  const [privatekey, setPrivatekey] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [publicKey, setPublicKey] = React.useState(null);
  const [clientId, setClientId] = React.useState(null);
  // const [clientSecret, setClientSecret] = React.useState(null);
  const [stripePromise, setStripePromise] = React.useState(null);
  const [hosted, setHosted] = React.useState(null);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [savingCard, setSavingCard] = React.useState(false);
  const [merchantLoading, setMerchantLoading] = React.useState(false);
  const [merchantLoaded, setMerchantLoaded] = React.useState(false);
  const [merchantScriptLoading, setMerchantScriptLoading] = React.useState(false);
  const [merchantScriptLoaded, setMerchantScriptLoaded] = React.useState(false);
  const [merchantScriptFailedLoading, setMerchantScriptFailedLoading] = React.useState(false);


  const updateClearentButtonDisabled = () => {
    const el = document.getElementById('Clearent-pay-now');

    if (!el) return;

    const disabled = !props.cartItemCount || !props.customer || !props.customer.source || props.transactionInProgress;

    if (el.hasAttribute('disabled') === disabled) return;

    el.disabled = disabled;
  };

  const updateClearentButtonHidden = (selectedCard_) => {
    const el = document.getElementById('ClearentPayButtonContainer');

    if (!el) return;

    el.style.display = selectedCard_ && selectedCard_.UUID === '' ? '' : 'none';
  };

  const updateEmergepayFormHidden = (selectedCard_) => {
    const el = document.getElementById('emergepay-form-container');

    if (!el) return;

    el.style.display = selectedCard_ && selectedCard_.UUID === '' ? 'flex' : 'none';
  };


  const purchaseWithExistingCard = async (UUID) => {
    // Purchase by sending the UUID of the existing card
    console.log('attempting savingCard using the following saved card UUID: ', UUID);

    props.setPaymentStatus({
      message: 'Processing Cart...',
      started: true,
    });

    

    const data = {};

    props.setPaymentStatus({
      message: 'Preparing Order...',
    });

    try {
      const { data: order } = await API.postOrderToCart(props.cartId, { currency: 'USD', description: 'Order from the web' });

      console.log('order: ', order);

      data.order = order;
    } catch (error) {
      console.log('postOrderToCart error: ', error);

      props.setPaymentStatus({
        message: 'Order creation failed.',
        finished: true,
        success: false,
      });

      setTimeout(() => {
        props.resetPaymentStatus();
      }, 1500);

      return;
    }

    const { total, customerId, locationId: locationId_, UUID: orderId } = data.order;

    if (total) {
      const saleModel = {
        amount: total,
        customerId,
        locationId: locationId_,
        paymentCardUUID: UUID,
      };

      props.setPaymentStatus({
        message: 'Processing Card Transaction...',
      });

      try {
        const { data: transaction } = await API.newSale(orderId, saleModel);

        console.log('transaction: ', transaction);
      } catch (error) {
        console.log('newSale error: ', error);

        props.setPaymentStatus({
          message: 'Transaction failed.',
          finished: true,
          success: false,
        });

        setTimeout(() => {
          props.resetPaymentStatus();
        }, 1500);

        return;
      }
    }

    props.setPaymentStatus({
      message: 'Transaction Successful! A receipt has been sent to your email.',
      finished: true,
      success: true,
    });

    setTimeout(() => {
      props.resetPaymentStatus();
      history.push(NAVIGATION.HOME);
      props.setCart(null);
    }, 1500);
  };

  const saveCardAndPurchase = async (merchantCardJSON) => {
    try {
      const { locationId } = props.cart;
      const payload = {
        locationId,
        merchantCardJSON,
      };
      const { data: savedCreditCard } = await API.putPaymentCard(payload);

      console.log('card saved successfully');
      console.log(savedCreditCard);

      setSavingCard(false);
      purchaseWithExistingCard(savedCreditCard.portalUUID);
    } catch (error) {
      setSavingCard(false);
      const errorMessage = getErrorMessage(error);

      console.log('putPaymentCard error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    }
  };

  const saveEmergepayCard = () => {
    setSavingCard(true);
    // wait for the magic to happen
    hosted.process();
    // When done, either onEmergePayTransactionSuccess or onEmergePayTransactionFailure will trigger
  };

  const saveStripeCard = async (elements, stripe) => {
    setSavingCard(true);

    // const result = await stripe.confirmCardSetup(clientSecret, {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //   },
    // });
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      console.log('stripe transaction failed');
      console.log(result.error.message);
      setSavingCard(false);
    } else {
      // saveCardAndPurchase(JSON.stringify(result.setupIntent));
      saveCardAndPurchase(JSON.stringify(result.paymentMethod));
    }
  };

  const beginNonClearentPurchaseFlow = (elements, stripe) => {
    if (!selectedCard) return;

    if (selectedCard.UUID) {
      purchaseWithExistingCard(selectedCard.UUID);
    } else if (merchant === 'stripe') {
      saveStripeCard(elements, stripe);
    } else {
      saveEmergepayCard();
    }
  };


  // eslint-disable-next-line
  window.ClearentOnPopupOpened = () => {
    console.log('clearent popup opened');
  };

  // eslint-disable-next-line
  window.ClearentOnError = (responseRaw, ResponseJSON) => {
    console.log('clearent transaction failed');
    console.log(responseRaw);
    console.log(ResponseJSON);

    if (ResponseJSON.payload && ResponseJSON.payload.error && ResponseJSON.payload.error['error-message']) {
      console.log(`error = ${ResponseJSON.payload.error['error-message']}`);
    } else {
      console.log('unable to determine error');
    }

    if (ResponseJSON.payload && ResponseJSON.payload.transaction && ResponseJSON.payload.transaction.id) {
      console.log(`transaction id = ${ResponseJSON.payload.transaction.id}`);
    } else {
      console.log('transaction id not found');
    }
  };

  // eslint-disable-next-line
  window.ClearentOnSuccess = async (responseRaw, ResponseJSON) => {
    console.log('clearent transaction successful');
    console.log(responseRaw);
    console.log(ResponseJSON);

    window.setTimeout(() => {
      document.getElementById('Clearent-close-button').click();
      saveCardAndPurchase(responseRaw);
    }, 1000);
  };

  const onClearentLoad = () => {
    console.log('loading clearent form, private key = ', privatekey);

    window.Clearent.payButton({
      pk: privatekey,
      'pay-button-parent': document.getElementById('payment-button-parent'),
      'request-type': 'CARD-TOKEN-ONLY',
      'heading-text': 'Enter card information below',
      'card-acceptance-label': '',
      'add-payment-button-text': 'Charge New Card',
      'submit-pay-method-button-text': 'Charge New Card',
      'success-message': 'Card information successfully saved!',
    });

    setMerchantScriptLoading(false);
    updateClearentButtonDisabled();
  };

  const onEmergePayFieldError = (fieldValidationError) => {
    setSavingCard(false);
    console.log('emergepay field validation error');
    console.log(fieldValidationError);

    toast.error(JSON.stringify(fieldValidationError));
  };

  const onEmergePayTransactionSuccess = (approvalData) => {
    console.log('emergepay transaction successful');
    console.log(approvalData);

    saveCardAndPurchase(JSON.stringify(approvalData));
  };

  const onEmergePayTransactionFailure = (failureData) => {
    setSavingCard(false);
    console.log('emergepay transaction failed');
    console.log(failureData);

    toast.error(JSON.stringify(failureData));
  };

  const onEmergePayLoad = () => {
    console.log('loading emergepay form, token = ', token);

    const hosted_ = window.emergepayFormFields.init({
      // (required) Used to set up each field
      transactionToken: token,
      // (required) The type of transaction to run
      transactionType: 'CreditSaveCard',
      // (optional) Configure which fields to use and the id's of the elements to append each field to
      fieldSetUp: {
        // These fields are valid for credit card transactions
        cardNumber: {
          appendToSelector: 'cardNumberContainer',
          useField: true,
        },
        cardExpirationDate: {
          appendToSelector: 'expirationDateContainer',
          useField: true,
        },
        cardSecurityCode: {
          appendToSelector: 'securityCodeContainer',
          useField: true,
        },
        // These fields are valid for all transaction types
        totalAmount: { useField: false },
        externalTranId: { useField: false },
      },
      // (optional) If there is a validation error for a field, the styles set in this object will be applied to the field
      fieldErrorStyles: {
        border: 'none',
        'box-shadow': '0px 0px 4px 1px red',
      },
      // (optional) This callback function will be called when there is a validation error for a field.
      onFieldError: onEmergePayFieldError,
      // (optional) Callback function that gets called after a successful transaction
      onTransactionSuccess: onEmergePayTransactionSuccess,
      // (optional) Callback function that gets called after a failure occurs during the transaction (such as a declined card)
      onTransactionFailure: onEmergePayTransactionFailure,
    });

    setHosted(hosted_);
    setMerchantScriptLoading(false);
  };

  const runMerchantScript = () => {
    console.log('running merchant script');
    console.log('merchant = ', merchant);
    console.log('privatekey = ', privatekey);
    console.log('token = ', token);

    if (merchant === 'clearent') {
      onClearentLoad();
    }
    if (merchant === 'emergepay') {
      onEmergePayLoad();
    }
  };

  React.useEffect(() => {
    if (merchantScriptLoaded) {
      runMerchantScript();
    }
    // eslint-disable-next-line
  }, [merchantScriptLoaded]);

  const onMerchantScriptLoaded = () => {
    console.log('merchant script finished loaded');
    setMerchantScriptLoaded(true);
  };

  const onMerchantScriptFailed = () => {
    console.log('merchant script failed loading');
    setMerchantScriptLoading(false);
    setMerchantScriptFailedLoading(true);
  };

  useEffectAsync(async () => {
    if (props.cart && merchantLoaded && merchant) {
      setMerchantScriptLoading(true);

      try {
        if (merchant === 'clearent') {
          loadDynamicScript(
            'clearent-hpp',
            APP_CONFIG.clearent,
            onMerchantScriptLoaded,
            onMerchantScriptFailed,
          );
        }

        if (merchant === 'emergepay') {
          loadDynamicScript(
            'emergepay-script',
            APP_CONFIG.emergePay,
            onMerchantScriptLoaded,
            onMerchantScriptFailed,
          );
        }

        if (merchant === 'stripe') {
          loadStripe(
            publicKey,
            { stripeAccount: clientId },
          ).then(
            (stripePromise_) => {
              setStripePromise(stripePromise_);
              setMerchantScriptLoading(false);
              onMerchantScriptLoaded();
            }
          ).catch(
            (error) => {
              console.log(error);
              onMerchantScriptFailed();
            }
          );
        }
      } catch (error) {
        const errorMessage = getErrorMessage(error);

        console.log('getMerchantPrivateKey error: ');
        console.log(errorMessage);

        toast.error(errorMessage);
        setMerchantScriptLoading(false);
      }
    }
  }, [props.cart, merchantLoaded, merchant]);

  useEffectAsync(async () => {
    if (props.cart) {
      setMerchantLoading(true);

      try {
        console.log('querying merchant and privatekey/token');
        const { data } = await API.getMerchantPrivateKey(props.cart.locationId);

        console.log('merchant: ', data.merchant);
        console.log('privatekey: ', data.privatekey);
        console.log('token: ', data.token);
        console.log('publicKey: ', data.publicKey);
        console.log('clientId: ', data.clientId);
        // console.log('client_secret: ', data.client_secret);

        setMerchant(data.merchant);
        setPrivatekey(data.privatekey);
        setToken(data.token);
        setPublicKey(data.publicKey);
        setClientId(data.clientId);
        // setClientSecret(data.client_secret);

        setMerchantLoading(false);
        setMerchantLoaded(true);
      } catch (error) {
        const errorMessage = getErrorMessage(error);

        console.log('getMerchantPrivateKey error: ');
        console.log(errorMessage);

        toast.error(errorMessage);
        setMerchantLoading(false);
      }
    }
  }, [props.cart]);


  React.useEffect(() => {
    updateClearentButtonHidden(selectedCard);
    updateEmergepayFormHidden(selectedCard);
  }, [selectedCard]);

  React.useEffect(() => {
    updateClearentButtonDisabled();
    // eslint-disable-next-line
  }, [props.cart, props.customer.source, savingCard, props.transactionInProgress]);


  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <div className="d-flex flex-column justify-content-start align-items-start">
            {merchant === 'emergepay' && (
              <div id="emergepay-form-container">
                <div id="cardNumberContainer">
                  {merchant === 'emergepay' && (
                    <InputLabel>
                      {'Card Number'}
                    </InputLabel>
                  )}
                </div>
                <div id="expirationDateContainer">
                  {merchant === 'emergepay' && (
                    <InputLabel>
                      {'Expiration Date'}
                    </InputLabel>
                  )}
                </div>
                <div id="securityCodeContainer">
                  {merchant === 'emergepay' && (
                    <InputLabel>
                      {'Security Code'}
                    </InputLabel>
                  )}
                </div>
              </div>
            )}
            {merchant === 'stripe' && stripePromise && selectedCard && selectedCard.UUID === '' && (
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            )}
            <div id="payment-button-parent">
              {selectedCard && (selectedCard.UUID !== '' || (merchant && merchant !== 'clearent' && merchantScriptLoaded)) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => beginNonClearentPurchaseFlow(elements, stripe)}
                  disabled={!props.cartItemCount || !props.customer || !props.customer.source || savingCard || props.transactionInProgress}
                >
                  {`Charge ${selectedCard && selectedCard.UUID !== '' ? selectedCard.nickname : 'New Card'}`}
                </Button>
              )}
            </div>
            <div className="pt-2 w-100">
              <TransactionStatus />
            </div>
          </div>
        )}
      </ElementsConsumer>
    </Elements>
  );
};

CreditCardForm.propTypes = {
  cart: PropTypes.object.isRequired,
  cartId: PropTypes.string.isRequired,
  cartItemCount: PropTypes.number.isRequired,
  customer: PropTypes.object.isRequired,
  resetPaymentStatus: PropTypes.func.isRequired,
  setCart: PropTypes.func.isRequired,
  setPaymentStatus: PropTypes.func.isRequired,
  transactionInProgress: PropTypes.bool.isRequired,
};


const mapStateToProps = (state) => ({
  cart: cartSelectors.cartSelector(state),
  cartId: cartSelectors.cartIdSelector(state),
  cartItemCount: cartSelectors.cartItemCount(state),
  customer: appSelectors.customer(state),
  transactionInProgress: cartSelectors.transactionInProgress(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetPaymentStatus: () => dispatch(cartActions.resetPaymentStatus()),
  setCart: (cart) => dispatch(cartActions.setCart(cart)),
  setPaymentStatus: (payload) => dispatch(cartActions.setPaymentStatus(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);
