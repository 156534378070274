import { useEffect } from 'react';

export const useEffectAsync = (effect, inputs, cleanup) => {
// eslint-disable-next-line
  useEffect(() => {
  // eslint-disable-next-line
    effect();
    // eslint-disable-next-line
    if (cleanup) {
    // eslint-disable-next-line
      return cleanup;
    // eslint-disable-next-line
    }
  // eslint-disable-next-line
  }, inputs);
};
