import { makeActionCreator } from 'service/utility';

export const LOAD_CART = 'LOAD_CART';
export const loadCart = makeActionCreator(LOAD_CART, 'cartId');

export const RESET_PAYMENT_STATUS = 'RESET_PAYMENT_STATUS';
export const resetPaymentStatus = makeActionCreator(RESET_PAYMENT_STATUS);

export const SET_CART = 'SET_CART';
export const setCart = makeActionCreator(SET_CART, 'cart');

export const SET_CART_LOADING = 'SET_CART_LOADING';
export const setCartLoading = makeActionCreator(SET_CART_LOADING, 'loading');

export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
export const setPaymentStatus = makeActionCreator(SET_PAYMENT_STATUS, 'payload');
