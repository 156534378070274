import React, {Fragment, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { appSelectors } from 'app/redux';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'service/utility';
import { getAllOrders, putOrder, getAllOffers } from 'service/api';
import moment from 'moment';


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  greenText : { color: '#009500' },
  orangeText : { color: '#d57800' },
  redText : { color: 'red' },
});


const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  
  const getClassName = (status) => {
    if (status === 'checked in') return classes.greenText;
    if (status === 'paid') return classes.orangeText;
    if (status === 'void') return classes.redText;
  }

  return (
    <TableRow className={classes.root}>
      <TableCell component="th" scope="row">{row.id}</TableCell>
      <TableCell> {row.offerName} </TableCell>
      <TableCell> {row.customerName} </TableCell>
      <TableCell>{row.customerEmail}</TableCell>
      <TableCell>{row.customerPhone}</TableCell>
      <TableCell className={getClassName(row.status)}>{row.formattedStatus}</TableCell>
      <TableCell>
        {row.status === 'paid' && (
          <Button size="small" onClick={props.checkIn(row)}>{'CHECK IN'}</Button>
        )}
        <Button size="small" component={Link} to={`/orders/${row.id}`}> {`VIEW`} </Button>
      </TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    customerEmail: PropTypes.string.isRequired,
    customerPhone: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    formattedStatus: PropTypes.string.isRequired,
  }).isRequired,
};



export const OrderHistory_ = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [orders, setOrders] = React.useState(null);
  const [offers, setOffers] = React.useState(null);

  const checkIn = (order) => async (event) => {
    const _order = { id: order.id, checkinDate: new Date(), status: 'checked in'};
    // _order.status = 'checked in'
    // delete _order["formattedStatus"];
    const result = await putOrder(order.locationId,_order);
    const _edited = result.data;
    const newState = orders.map(obj =>
      obj.id === _edited.id ? { ...obj, status: _edited.status, checkinDate: _edited.checkinDate } : obj
    );
    setOrders(formatData(newState));
  };


  useEffect(() => {
    const getOrders = async () => {
      setLoading(true);
      try {
        const { data:offers } = await getAllOffers(props.location.id);
        
        if(offers) {
          setOffers(offers);
        }
        
        const { data } = await getAllOrders(props.location.id);
        if(data) {
          const orders = formatData(data).sort((a, b) => {
            return b.id - a.id;
          });;
          setOrders(orders);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(getErrorMessage(error));
      } 
    };
    props.location.id && getOrders();
  },[]);

  const formatData = (data) => {
    const formatted = data.map(item => {
      let formattedDate;
      if (item.status === 'paid') {
        formattedDate = moment(item.purchaseDate).format('MM/DD/YYYY h:mm a'); 
      }
      if (item.status === 'checked in') {
        formattedDate = moment(item.checkinDate).format('MM/DD/YYYY h:mm a'); 
      }
      // this won't work yet, need to tell stripe to call when an order is refunded
      if (item.status === 'refunded') {
        formattedDate = moment(item.updatedAt).format('MM/DD/YYYY h:mm a'); 
      }
      const formattedString = item.status.toString().toUpperCase();
      item = {...item, formattedStatus: `${formattedString} at ${formattedDate}`};
      
      const offer = offers ? offers.find(off => off.id === item.offerId) : '';
      console.log(offers);
      item['offer'] = offer;

      return item;
    });
    return formatted;
  }

  return (
    <Fragment>
    <Typography variant="h4" gutterBottom classes={{ h4: 'header-text' }}>Order History <Button>Location Filter</Button></Typography>
    <Typography variant="h6" gutterBottom classes={{ root: 'header-underline-1x'}}>Recent Orders</Typography>
    <TextField fullWidth variant="outlined" size="small" 
      InputProps={{
        startAdornment: ( <InputAdornment position="start"> <SearchIcon /> </InputAdornment> )
      }}
    />
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Order #</TableCell>
            <TableCell>Offer</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Order Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? (orders && orders.map((row) => (
            <Row key={row.id} row={row} checkIn={checkIn} />
          ))) : 
          (<TableRow><TableCell>{'Fetching orders...'}</TableCell></TableRow>)
          }
        </TableBody>
      </Table>
    </TableContainer>
    </Fragment>
  );
}


const mapStateToProps = (state) => ({
  location: appSelectors.location(state),
});

export const OrderHistory = connect(mapStateToProps)(OrderHistory_);