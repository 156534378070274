import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { useEffectAsync } from 'service/utility';
import * as authService from 'service/firebase';

export const PrivateRoute_ = (props) => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffectAsync(async () => {
      // const user = await Auth.currentAuthenticatedUser();
      const userToken = await authService.getCurrentUser();
    if(userToken){
      setAuthenticated(true);
    } else {
      // No authenticated user, need to redirect
      console.log('No authenticated user');
      props.history.push('/login');
    }
  }, []);

  if (!authenticated) return null;

  return <Route {...props} />;
};

PrivateRoute_.propTypes = {
  history: PropTypes.object.isRequired,
};

export const PrivateRoute = withRouter(PrivateRoute_);
