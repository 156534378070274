import React, {Fragment,useEffect} from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import {getAllOffers} from 'service/api';
import { appSelectors } from 'app/redux';
import { toast } from 'react-toastify';
import { ThreeDots } from 'common/statusIndicators';
import { getErrorMessage } from 'service/utility';
import * as API from 'service/api';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';

export const OfferList_ = (props) => {
  const [offers, setOffers] = React.useState([]);
  const locationId = props.location? props.location.id : 0;
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    const now = DateTime.local();
    const ianaTimeZone = now.toFormat('z');

    const getOffers = async () => {
      setLoading(true);
      try {
        const { data } = await getAllOffers(locationId);
        if(data) {
          data.forEach(offer => {
            offer["startDate"] = moment.parseZone(offer.startDate).format('MM/DD/YYYY'); 
            offer["endDate"] = moment.parseZone(offer.endDate).format('MM/DD/YYYY'); 
          });
          setOffers(data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(getErrorMessage(error));
      } 
    };
    locationId !== 0 && getOffers();
  },[locationId]);

  const formatDate = (date) => {
    let d = new Date(date);
    let fd = d.toLocaleDateString();
    return fd;
  }

  const handleChange = (offer) => async (event) => {
    const result = await API.putOffer(offer.locationId,{id:offer.id,isPublished:event.target.checked});
    const _edited = result.data;
    const _offers = JSON.parse(JSON.stringify(offers));
    // const offIndex = _offers.findIndex((obj => obj.id === _edited.id));
    // _offers[offIndex] = _edited;
    // console.log(_offers);
    // setOffers(_offers);
    const newState = offers.map(obj =>
      obj.id === _edited.id ? { ...obj, isPublished: _edited.isPublished } : obj
    );
    setOffers(newState);
  };

  return (
    <Fragment>
    <Typography variant="h4" gutterBottom classes={{ h4: 'header-text' }}>Offers
      <Button className="right-button" component={RouterLink} to="/offers/new">
        Create Offer
      </Button>
    </Typography>

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name
              <KeyboardArrowUpOutlinedIcon />
            </TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Time range</TableCell>
            <TableCell>Usage</TableCell>
            <TableCell>Active</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? (offers.map((offer) => (
            <TableRow key={offer.id}>
              <TableCell>
                <img src={offer.imageURL} width="50px" />
              </TableCell>
              <TableCell component="th" scope="row">
                {offer.name}
              </TableCell>
              <TableCell>{offer.description}</TableCell>
              <TableCell>{offer.type === 'fixed' ? 'Fixed' : 'Capacity'}</TableCell>
              <TableCell>{`${offer.startDate} to ${offer.endDate}`}</TableCell>
              <TableCell>{`${offer.startTime} to ${offer.endTime}`}</TableCell>
              <TableCell>{`${offer.totalUsed} / ${offer.totalAvailable}`}</TableCell>
              <TableCell>
                <Switch
                  checked={offer.isPublished == 1}
                  onChange={handleChange(offer)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </TableCell>
              <TableCell align="right"><Button component={RouterLink} to={`/offers/edit/${offer.id}`}>Edit</Button></TableCell>
            </TableRow>
          ))) : ( 
          <TableRow><TableCell><ThreeDots /></TableCell></TableRow> 
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  location: appSelectors.location(state),
});

export const OfferList = connect(mapStateToProps)(OfferList_);