import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';


import { authSelectors } from 'app/auth';
import { appSelectors } from 'app/redux';
import { SideBar, TopBar, EmptyTopBar } from 'common/layout';
import { useLocation } from 'react-router-dom'
import './layout.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1
  },
}));

const Layout_ = (props) => {
  const classes = useStyles();
  const { sidebar: { isOpen } } = props;
  const location = useLocation();
  const locString = location.pathname;
  const isCart = (locString.indexOf('cart') !== -1) || (locString.indexOf('checkout')) !== -1 || (locString.indexOf('order-confirmation') !== -1);
  // TODO display topbar when there's no user AND we're in /cart or /checkout
  // DO NOT display topbar & sidebar even when there's user and we're in /cart or /checkout
  return (
    <Fragment>
      { (props.authUser && !isCart) && ( <TopBar /> ) } 
      { (props.authUser && !isCart) && ( <SideBar /> )}
      <main id="content" className={`${props.authUser ? 'withUser' : 'noUser'} ${classes.content}`}>
        {props.children}
      </main>
    </Fragment>
  );  
}

Layout_.propTypes = {
  authUser: PropTypes.object,
  sidebar: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]),
};

const mapStateToProps = (state) => ({
  authUser: authSelectors.authUser(state),
  sidebar: appSelectors.sidebar(state)
});

const Layout = connect(mapStateToProps)(Layout_);

export default Layout;
