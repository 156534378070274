import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LocationIcon from '@material-ui/icons/LocationOn';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { appSelectors } from 'app/redux';
import { appActions } from 'app/redux';
import { SignOut } from 'app/auth/views';
import { AppTitle } from 'common/layout';
import {LocationStripe}  from 'common/LocationStripe';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#172c5f',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  title: {
    flexGrow: 1
  },
  locSelector: {
    marginRight: '2em'
  },
  whiteText: {
    color: '#fffffff'
  }
}));

const TopBar_ = (props) => {
  const classes = useStyles();
  const { sidebar: { isOpen } } = props;

  const handleDrawerOpen = () => {
    // setOpen(true);
    props.actions.setSidebarExpanded();
  };

  const handleDrawerClose = () => {
    // setOpen(false);
    props.actions.setSidebarCollapsed();
  };


  return (
    <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <AppTitle />
          <LocationStripe />
          {/* <LocationSelector className={classes.locSelector}/> */}
          <SignOut />
        </Toolbar>
      </AppBar>
  );
}

TopBar_.propTypes = {
  actions: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  sidebar: appSelectors.sidebar(state),
  location: appSelectors.location(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setSidebarCollapsed: () => dispatch(appActions.setSidebarCollapsed()),
    setSidebarExpanded: () => dispatch(appActions.setSidebarExpanded())
  }
});
export const TopBar = connect(mapStateToProps, mapDispatchToProps)(TopBar_);
