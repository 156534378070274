import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useEffectAsync, getErrorMessage } from 'service/utility';
import { makeStyles } from '@material-ui/core/styles';
import { getOrderPublic } from 'service/api';
import { connect } from 'react-redux';
import { cartSelectors } from 'app/cart';
import { Spinner } from 'common/statusIndicators';
import moment from 'moment-timezone';

const lightText = 'rgba(6,52,94,0.9)';
const regularText = 'rgba(6,52,94,1)';
const blackText = '#555555';

const useStyles = makeStyles((theme) => ({
  soil: {
    display: 'flex',
  },
  root: {
    flexGrow: 1,
    padding: '2rem',
    margin: 'auto',
    maxWidth: '980px'
  },
  title: {
    textAlign: 'center'
  },
  sectionTitle: {
    fontWeight: 300,
    fontSize: '1.6em'
  },
  detailTitle: {
    color: lightText,
    fontWeight: 300,
    display: 'block'
  },
  detailContent: {
    color: regularText,
    fontWeight: 500,
    fontSize: '1.5em'
  },
  terms: {
    fontWeight: 300,
    fontSize: '1.2em',
    color: blackText
  },
  borderRight: {
    // borderRight: '1px solid rgba(6,52,94,0.7)'
  },
  footPrint: {
    color: blackText,
    fontSize: '0.9em',
    textAlign: 'center'
  }
}));

const OrderConfirmation = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState(null);  
  const [location, setLocation] = React.useState(null);  
  const [offer, setOffer] = React.useState(null);  
  const [orderTotal, setOrderTotal] = React.useState(null);  
  const [salePrice, setSalePrice] = React.useState(null);  
  const [orderDate, setOrderDate] = React.useState(null);  
  const [loading, setLoading] = React.useState(false);

  const {paymentStatus : { actions : { offerId, locationId, orderId, accountId }}} = props;

  useEffectAsync(async () => {
    try {
      const { data } = await getOrderPublic(accountId, locationId, orderId);
      setLoading(true);
      if(data) { 
        const {offer, location, order} = data;
        setOrder(order);
        setLocation(location);
        setOffer(offer);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast.error(getErrorMessage(error));
    } 
  },[]);

  React.useEffect(() => {
    if(offer && order) {
      const _salePrice = '$' + (offer.salePrice / 100);
      const _orderTotal = '$' + (order.orderTotal / 100);
      const _orderDate = moment(order.purchaseDate).format('MM/DD/YYYY h:mm a');
      setSalePrice(_salePrice);
      setOrderTotal(_orderTotal);
      setOrderDate(_orderDate);
    }
  },[offer,order]);

  return (
    <div className={classes.soil}>
      <div className={classes.root}>
        { (order && offer && location) ? 
        (
          <Grid container spacing={3}>
          <Grid item xs={12}><Typography variant="h3" className={classes.title}>Thank you</Typography></Grid>
          <Grid item xs={12}>
            <p className={classes.sectionTitle}>Order details</p>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" className={classes.borderRight}>
                  <Grid item>
                    <span className={classes.detailTitle}>Order number</span>
                    </Grid>
                  <Grid item>
                    <span className={classes.detailContent}>{`#${order.id}`}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" className={classes.borderRight}>
                  <Grid item>
                    <span className={classes.detailTitle}>Order date</span>
                    </Grid>
                  <Grid item>
                    <span className={classes.detailContent}>{orderDate}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column">
                  <Grid item>
                    <span className={classes.detailTitle}>Payment method</span>
                    </Grid>
                  <Grid item>
                    <span className={classes.detailContent}>{order.paymentMethod}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" className={classes.borderRight}>
                  <Grid item className={classes.detailTitle}>Offer</Grid>
                  <Grid item>{offer.name}</Grid>
                  <Grid item>{offer.product}</Grid>
                  <Grid item>{salePrice}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" className={classes.borderRight}>
                  <Grid item className={classes.detailTitle}>Location</Grid>
                  <Grid item>{location.name}</Grid>
                  <Grid item>{location.address}</Grid>
                  <Grid item>{location.phone}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column">
                  <Grid item className={classes.detailTitle}>Customer</Grid>
                  <Grid item>{order.customerName}</Grid>
                  <Grid item>{order.customerEmail}</Grid>
                  <Grid item>{order.customerPhone}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.sectionTitle}>Terms and conditions</p>
            <p className={classes.terms}>{offer.terms}</p>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}><p className={classes.sectionTitle}>Order Summary</p></Grid>
              <Grid item xs={4} sm={4}>
              <Grid container direction="column" className={classes.borderRight}>
                <Grid item className={classes.detailTitle}>Subtotal</Grid>
                <Grid item className={classes.detailContent}>{salePrice}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Grid container direction="column" className={classes.borderRight}>
                <Grid item className={classes.detailTitle}>Tax</Grid>
                <Grid item className={classes.detailContent}>{offer.tax ? `${offer.tax}%` : 'no tax'}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={4}>
              <Grid container direction="column">
                <Grid item className={classes.detailTitle}>Total</Grid>
                <Grid item className={classes.detailContent}>{orderTotal}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.footPrint}>
              Please contact {location.name} at <a href={`tel:${location.phone}`}>{location.phone}</a> for questions <br/>
              Please save a copy of this or take a screen shot in order to redeem your purchase <br />
              You may now close this page
            </div>
          </Grid>
        </Grid>
        )
        
        :
        (<Spinner />)
        }
      </div>
      </div>
  );
}

OrderConfirmation.propTypes = {
  match: PropTypes.object.isRequired,
  paymentStatus: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  paymentStatus: cartSelectors.paymentStatusSelector(state),
});

export default connect(mapStateToProps)(OrderConfirmation);