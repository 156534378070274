export const formatCurrency = (value, currency = '$') => {
  if (value === null || typeof value === 'undefined') return '--';
  const num = Number(value);

  return num < 0 ? `-${currency}${(-num).toFixed(2).toString()}` : `${currency}${num.toFixed(2).toString()}`;
};

export const round = (val, decimals) => Number(`${Math.round(`${val}e${decimals}`)}e-${decimals}`);

export const toCents = (value) => value * 100;

export const percentageOfPrice = (price, percentage) => round((price * percentage / 100), 2);

export const leftPadZero = (num, width) => num.toString().padStart(width, 0);
