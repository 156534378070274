import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Login } from 'app/auth';
import { PrivateRoute } from 'common/navigation';
import * as NAVIGATION from 'config/navigation';
import NoMatch from './NoMatch';
import { Profile } from 'app/profile';
import { OfferList, OfferDetail, TodaysOffers, NewOffer, EditOffer } from 'app/offers';
import { CounterData, LiveCounters } from 'app/counters';
import { OrderHistory, OrderDetail } from 'app/orders';
import { Traffic } from 'app/traffic';
import { Authorizations } from 'app/authorizations';
import Checkout from 'app/checkout';
import { Cart } from 'app/cart';
import { StripeCallback } from 'app/StripeCallback';
import { OrderConfirmation } from 'app/orders';
const Routes = () => (
  <Switch>
    <Route
      path={NAVIGATION.LOGIN}
      component={Login}
    />
    <PrivateRoute
      path={NAVIGATION.PROFILE}
      component={Profile}
    />
    <PrivateRoute
      path={NAVIGATION.OFFER_LIST}
      exact
      component={OfferList}
    />
    <PrivateRoute
      path={NAVIGATION.OFFER_NEW}
      component={NewOffer}
    />
    <PrivateRoute
      path={`${NAVIGATION.OFFER_EDIT}/:offerId`}
      component={EditOffer}
    />
    <PrivateRoute
      path={NAVIGATION.TODAYS_OFFERS}
      component={TodaysOffers}
    />
    <PrivateRoute
      path={NAVIGATION.ORDER_HISTORY}
      component={OrderHistory}
    />
    <PrivateRoute
      path={`${NAVIGATION.ORDERS}/:orderDetailId`}
      component={OrderDetail}
    />
    <PrivateRoute
      path={NAVIGATION.LIVE_COUNTERS}
      component={LiveCounters}
    />
    <PrivateRoute
      path={NAVIGATION.COUNTER_DATA}
      component={CounterData}
    />
    <PrivateRoute
      path={NAVIGATION.TRAFFIC}
      component={Traffic}
    />
    <PrivateRoute
      path={NAVIGATION.AUTHORIZATIONS}
      component={Authorizations}
    />
    <Route
      path={NAVIGATION.CART}
      component={Cart}
    />
    <Route
      path={NAVIGATION.CHECKOUT}
      component={Checkout}
    />
    <Route
      path={NAVIGATION.ORDER_CONFIRMATION}
      component={OrderConfirmation}
    />
    <Route
      path={NAVIGATION.STRIPE_CALLBACK}
      component={StripeCallback}
    />
    <PrivateRoute component={NoMatch} />
  </Switch>
);

export default Routes;