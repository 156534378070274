import React from 'react';
import { PropTypes } from 'prop-types';

import { getAddressArray } from 'service/utility';


const LocationDetails = (props) => {
  const addressArray = getAddressArray(props.location);

  return (
    <div className="w-100 d-flex flex-column justify-content-start align-items-start">
      {!props.location.id ? (
        <span>
          {'No Location Selected.'}
        </span>
      ) : (
        <React.Fragment>
          <span>
            {props.location.name}
          </span>
          {addressArray.length ? (
            addressArray.map((addressRow, idx) => (
              <span key={idx}>
                {addressRow}
              </span>
            ))
          ) : (
            <span>
              {'No Address Information.'}
            </span>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

LocationDetails.propTypes = {
  location: PropTypes.object.isRequired,
};


export default LocationDetails;
