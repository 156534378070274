import moment from 'moment-timezone';
export { createReducer } from './createReducer';
export { makeActionCreator } from './makeActionCreator';
export { useEffectAsync } from './useEffectAsync';

export const getStatus = (status) => {
  switch (status) {
    case 200: return 'OK';
    case 301: return 'Moved Permanently';
    case 400: return 'Bad Request';
    case 401: return 'Unauthorized';
    case 403: return 'Forbidden';
    case 404: return 'Not Found';
    case 408: return 'Request Timeout';
    case 500: return 'Internal Server Error';
    case 501: return 'Not Implemented';
    case 502: return 'Bad Gateway';
    case 503: return 'Service Unavailable';
    case 504: return 'Gateway Timeout';
    default: return '';
  }
};

export const getErrorMessage = (error) => {
  console.error(error);
  if (typeof error === 'string') return error;
  if (typeof error === 'undefined') return 'Unknown error';
  if (typeof error !== 'object') return JSON.stringify(error);

  const { response } = error;

  if (typeof response === 'string') return response;
  if (typeof response === 'undefined') return 'Unknown error';
  if (typeof response !== 'object') return JSON.stringify(response);

  const { data } = response;

  if (typeof data === 'string') return data;
  if (typeof data === 'undefined') return 'Unknown error';
  if (typeof data !== 'object') return JSON.stringify(data);

  const { message } = data;

  if (typeof message === 'string') return message;
  if (typeof message === 'undefined') return 'Unknown error';

  return JSON.stringify(message);
};

export const loadDynamicScript = (scriptId, url, onLoad, onError) => {
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');

    script.src = url;
    script.id = scriptId;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (onLoad) onLoad();
    };

    script.onerror = () => {
      if (onError) onError();
    };
  }

  if (existingScript && onLoad) onLoad();
};

export const getCurrentCountry = (customer) => (customer && customer.countryCode) || 'US';

// export const getCurrentCurrencySymbol = (customer) => (customer && customer.currencySymbol) || '$';

export const getCurrentTimezone = () => moment.tz.guess();

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const objectHasErrors = (o) => Object.values(o).some((e) => e);

export const commaJoin = (arr) => arr.filter((e) => e).join(',');

export const commaSpaceJoin = (arr) => arr.filter((e) => e).join(', ');

export const getAddressArray = (o) => {
  const response = [];

  if (typeof o === 'object' && Boolean(o)) {
    const { address1, address2, city, state, postalCode } = o;

    if (address1 || address2) {
      response.push(commaSpaceJoin([address1, address2]));
    }
    if (city || state || postalCode) {
      response.push(commaSpaceJoin([city, state, postalCode]));
    }
  }

  return response;
};
