import React from 'react';
import { PropTypes } from 'prop-types';
import { PicklistField } from 'common/formFields';
import { TIMEZONE_PICKLIST_VALUES } from 'config/timezones';
import { getCurrentTimezone } from 'service/utility';

export const TimezoneField = (props) => {
  const { dispatch, ...rest } = props;
  const currentTimezone = getCurrentTimezone();

  if (currentTimezone && !rest.value) {
    props.onChange({ value: currentTimezone });

    return null;
  }

  return (
    <PicklistField
      {...rest}
      picklistValues={TIMEZONE_PICKLIST_VALUES}
    />
  );
};

TimezoneField.propTypes = {
  dispatch: PropTypes.func,
  onChange: PropTypes.func,
};

TimezoneField.defaultProps = {
  value: '',
};
