import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { cartSelectors } from 'app/cart';
import { cartActions } from 'app/cart';
import { appActions, appSelectors } from 'app/redux';
import { CartTotals, CustomerDetails, LocationInformation, CreditCardForm, StripeCreditCardForm } from 'common/cart';
import * as NAVIGATION from 'config/navigation';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      marginTop: '-0.2rem'
    },
    '& .MuiInput-formControl': {
      margin: '0.75rem 0'
    }
  },
  textField: {
    margin: '1rem 0'
  },
  topbarLogo: {
    height: '40px'
  }
}));

const Checkout = (props) => {
  const classes = useStyles();

  const { cart, paymentStatus } = props;
  // props.setPaymentStatus({});

  const { cart:actualCart } = cart;
  const { customer, offer, location, termsAgreed, total } = actualCart;
  const { loadCart } = props;
  const { tax } = offer;
  const history = useHistory();

  const displayTotal = total / 100;
  const amountToCharge = tax ? (total + (total * (tax/100))) : total;
  const displayAmountToCharge = amountToCharge / 100;

  const [loadingStripe, setLoadingStripe] = React.useState(false);
  const [checkingOut, setCheckingOut] = React.useState(false);
  const [paymentFailed, setPaymentFailed] = React.useState(false);

  React.useEffect(() => {
    const ps = props.paymentStatus;
    if(ps.finished) {
      if(ps.success) {
        // redirect to confirmation screen
        props.history.push('/order-confirmation');
      } else {
        setPaymentFailed(true);
      }
    }
  },[props.paymentStatus]);

  // this is a really shitty way of showing the confirmation screen
  // should go trough props or redux ideally
  return (
    <React.Fragment>
      <div className="container pt-3 pb-3 mb-6">
      <h1 className="text-center"><img className={classes.topbarLogo} src={require('assets/pc-logo-wb.svg')} alt="PeopleCount" /></h1>
        <div className="row">
          <div className="col-md-6 d-flex flex-column">
            <div className="pt-4 w-100">
              <div className="d-flex flex-column justify-content-start align-items-start">
                <span className="font-weight-thick text-small">
                  {'Offer'}
                </span>
                <div className="border rounded w-100 p-3">
                  <div className="w-100 d-flex flex-column justify-content-start align-items-start">
                    {offer && (
                      <React.Fragment>
                        <span>
                          {offer?.name}
                        </span>
                        <span>
                          {offer?.description}
                        </span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <span className="font-weight-thick text-small">
                  {'Location'}
                </span>
                <div className="border rounded w-100 p-3">
                  <div className="w-100 d-flex flex-column justify-content-start align-items-start">
                    {location && (
                      <React.Fragment>
                        <span>
                          {location?.name}
                        </span>
                        <span>
                          {location?.address || 'No Address Information.'}
                        </span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start">
              <span className="font-weight-thick text-small">
                  {'Customer'}
              </span>
              <div className="border rounded w-100 p-3">
                <div className="w-100 d-flex flex-column justify-content-start align-items-start">
                  {customer && (
                    <React.Fragment>
                      <span>
                        {customer.name}
                      </span>
                      <span>
                        {customer.email}
                      </span>
                      <span>
                        {customer.phone}
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6 pt-4 d-flex flex-column">
            
            <div className="d-flex pt-2 flex-column justify-content-start align-items-start">
              <span className="font-weight-thick text-small">
                {'Total'}
              </span>
              <div className="border-top w-100 d-flex flex-column justify-content-start align-items-start">
                { total && (
                  <div className="row no-gutters w-100">
                    <div className="col-md-8 offset-md-4">
                      <table className="table table-borderless">
                        <tbody>
                          <tr className="text-right">
                            <td>Product</td>
                            <td>
                              {offer.product}
                            </td>
                          </tr>
                          <tr className="text-right">
                            <td>Price</td>
                            <td>
                              {`$${displayTotal}`}
                            </td>
                          </tr>
                          <tr className="text-right">
                            <td>Tax</td>
                            <td>
                              {tax > 0 ? `${tax}%` : 'No tax'}
                            </td>
                          </tr>
                          <tr className="text-right">
                            <th>Total</th>
                            <th>
                              {`$${displayAmountToCharge}`}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="pt-4 w-100">
              <StripeCreditCardForm />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

};

Checkout.propTypes = {
  cart: PropTypes.object,
  history: PropTypes.object.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  location: PropTypes.object,
  setPaymentStatus: PropTypes.func
};


const mapStateToProps = (state) => ({
  cart: cartSelectors.cartSelector(state),
  isCartLoading: cartSelectors.isCartLoading(state),
  location: appSelectors.location(state),
  paymentStatus: cartSelectors.paymentStatusSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadCart: (offerId) => dispatch(cartActions.loadCart(offerId)),
  setPaymentStatus: (payload) => dispatch(cartActions.setPaymentStatus(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
