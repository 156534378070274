import { APP_NAME } from 'config/app';


const getKeyName = (key) => `@${APP_NAME}_${key}`;

const stateKey = getKeyName('state');


export const loadState = () => {
  const serializedState = localStorage.getItem(stateKey);
  const state = serializedState ? JSON.parse(serializedState) : {};
  return state;
};

export const saveState = (state) => {
  const serializedState = JSON.stringify({
    app: {
      account: state.app.account,
      location: state.app.location,
      locations: state.app.locations,
      offers: state.app.offers,
      newOffer: state.app.newOffer,
    },
    cart: {
      
    },
    layout: {
      sidebar: state.layout.sidebar,
    },
    auth: {
      user: state.auth.user,
    },
  });

  localStorage.setItem(stateKey, serializedState);
};
