import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { MenuItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { PersonAvatar } from 'common/avatars';
import { GenericSearch } from 'common/searches';
// import * as crmAPI from 'service/crm/api';
import { formatName } from 'service/utility/stringFormatters';
import { formatPhone } from 'service/utility/phoneNumber';

const CustomerSearchItem = (props) => {
  const { children, isFocused, data: { imageURL, mobile, home, email }, innerProps: { onClick } } = props;
  const secondary = mobile ? formatPhone(mobile) : home ? formatPhone(home) : (email || null);

  return (
    <MenuItem
      selected={isFocused}
      onClick={onClick}
      classes={{ root: 'customer-search-item' }}
    >
      <ListItemAvatar>
        <PersonAvatar
          src={imageURL}
          size="md"
        />
      </ListItemAvatar>
      <ListItemText
        primary={children.toString()}
        secondary={secondary}
        className="customer-name"
      />
    </MenuItem>
  );
};

CustomerSearchItem.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
};

export class CustomerSearch extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    cacheOptions: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    filter: PropTypes.func,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    autoFocus: false,
    cacheOptions: true,
    className: '',
    disabled: false,
    error: '',
    fullWidth: true,
    required: false,
  };

  // getOptions = (input) => crmAPI.searchCustomers(`q=${input}`).then(
  //   ({ data: { data } }) => (this.props.filter ? this.props.filter(data) : data),
  // );

  getOptions = () => [];

  getOptionValue = (option) => option.id;

  getOptionLabel = (option) => formatName(option);

  render() {
    const { autoFocus, cacheOptions, className, disabled, error, fullWidth, label, required, value, onChange, ...rest } = this.props;

    return (
      <GenericSearch
        label={label}
        value={value}
        error={error}
        suggestionItem={CustomerSearchItem}
        onChange={onChange}
        getOptions={this.getOptions}
        getOptionValue={this.getOptionValue}
        getOptionLabel={this.getOptionLabel}
        autoFocus={autoFocus}
        required={required}
        fullWidth={fullWidth}
        className={className}
        disabled={disabled}
        cacheOptions={cacheOptions}
        {...rest}
      />
    );
  }
}
