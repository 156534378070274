import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { Menu, MenuItem } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PhotoIcon from '@material-ui/icons/Photo';
import { Spinner, StatusOverlay } from 'common/statusIndicators';
import { ImageDialog } from 'common/images';

export class ImageControlView extends Component {
  static propTypes = {
    avatar: PropTypes.bool.isRequired,
    handleCancelModal: PropTypes.func.isRequired,
    handleChangeImage: PropTypes.func.isRequired,
    handleNewImage: PropTypes.func.isRequired,
    imageURL: PropTypes.string,
    isModalOpen: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    size: PropTypes.number,
    uploading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleEditPhotoClick = (event) => {
    const { imageURL, handleChangeImage } = this.props;

    if (imageURL) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      handleChangeImage();
    }
  };

  handleCloseMenu = () => this.setState({ anchorEl: null });

  handleRemoveClick = () => {
    this.handleCloseMenu();
    this.props.onDelete();
  };

  handleChangeClick = () => {
    this.handleCloseMenu();
    this.props.handleChangeImage();
  };

  render() {
    const { avatar, isModalOpen, handleNewImage, handleCancelModal, imageURL, size, uploading } = this.props;
    const { anchorEl } = this.state;

    return (
      <Fragment>
        <div
          className="image-control"
          style={{
            width: `${size || '6'}rem`,
          }}
        >
          {uploading && (
            <StatusOverlay>
              <Spinner size={30} />
            </StatusOverlay>
          )}
          <div
            className={classnames('image-wrap', { 'rounded-circle': avatar })}
            style={{
              fontSize: `${size || '6'}rem`,
            }}
          >
            {imageURL ? (
              <img
                src={imageURL}
                alt=""
              />
            ) : avatar ? (
              <PersonIcon fontSize="inherit" />
            ) : (
              <PhotoIcon fontSize="inherit" />
            )}
          </div>
          <div
            className="trigger mt-1 text-center"
            onClick={(event) => this.handleEditPhotoClick(event)}
          >
            <span>{'Edit photo'}</span>
          </div>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.handleCloseMenu()}
          disableAutoFocusItem
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
        >
          {imageURL && (
            <MenuItem onClick={() => this.handleRemoveClick()}>
              {'Remove'}
            </MenuItem>
          )}
          <MenuItem onClick={() => this.handleChangeClick()}>
            {'Change'}
          </MenuItem>
        </Menu>
        <ImageDialog
          handleNewImage={handleNewImage}
          isOpen={isModalOpen}
          handleCancel={handleCancelModal}
        />
      </Fragment>
    );
  }
}
