import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Fab, Typography } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Webcam from 'react-user-media';
import { ImageCropper } from 'common/images';
import { Spinner } from 'common/statusIndicators';

export class WebcamDialogView extends Component {
  static propTypes = {
    captured: PropTypes.bool.isRequired,
    cropped: PropTypes.bool.isRequired,
    croppedImgStr: PropTypes.string,
    handleCapture: PropTypes.func.isRequired,
    handleCrop: PropTypes.func.isRequired,
    handleRetake: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    imgStr: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      webcamLoaded: false,
      webcamDetected: false,
    };
  }

  handleWebcamSuccess = () => {
    console.log('Webcam successfully detected');

    this.setState({
      webcamLoaded: true,
      webcamDetected: true,
    });
  };

  handleWebcamError = () => {
    console.log('No webcam detected');

    this.setState({
      webcamLoaded: true,
      webcamDetected: false,
    });
  };

  render() {
    const { handleCapture, handleRetake, handleCrop, handleSave, croppedImgStr, imgStr, captured, cropped } = this.props;
    const { webcamLoaded, webcamDetected } = this.state;

    return (
      <div className="webcam-dialog">
        {(captured && imgStr) ? (
          <div className="image-cropper-wrapper">
            <ImageCropper
              imgSrc={imgStr}
              handleCroppedImage={(e) => handleCrop(e)}
            />
            {(cropped && croppedImgStr) ? (
              <div className="buttons-container">
                <Button
                  color="secondary"
                  onClick={handleRetake}
                >
                  {'Retake'}
                </Button>
                <Button
                  color="primary"
                  onClick={handleSave}
                >
                  {'Done Cropping'}
                </Button>
              </div>
            ) : (
              <Typography
                variant="subtitle1"
                align="center"
              >
                {'Crop Photo'}
              </Typography>
            )}
          </div>
        ) : (
          <div className="webcam-wrapper">
            {!webcamLoaded && (
              <Spinner />
            )}
            {webcamLoaded && !webcamDetected && (
              <Typography classes={{ root: 'font-italic' }}>
                {'No webcam detected.'}
              </Typography>
            )}
            <Webcam
              ref={(ref) => this.webcam = ref}
              audio={false}
              width={300}
              height={225}
              captureFormat="image/jpeg"
              onSuccess={() => this.handleWebcamSuccess()}
              onFailure={() => this.handleWebcamError()}
            />
            {webcamLoaded && webcamDetected && (
              <div className="photo-button-wrapper">
                <Fab
                  color="primary"
                  onClick={() => handleCapture(this.webcam.captureScreenshot())}
                >
                  <PhotoCamera />
                </Fab>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
