import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { appSelectors } from 'app/redux';
import { PicklistField } from 'common/formFields';
import { COUNTRY_PICKLIST_VALUES } from 'config/countries';
import { getCurrentCountry } from 'service/utility';

const CountryField_ = (props) => {
  const { customer, dispatch, ...rest } = props;
  const currentCountry = getCurrentCountry(customer);

  if (currentCountry && !rest.value) {
    props.onChange({ value: currentCountry });

    return null;
  }

  return (
    <PicklistField
      {...rest}
      picklistValues={COUNTRY_PICKLIST_VALUES}
    />
  );
};

CountryField_.propTypes = {
  customer: PropTypes.object,
  dispatch: PropTypes.func,
  onChange: PropTypes.func,
};

CountryField_.defaultProps = {
  value: '',
};

const mapStateToProps = (state) => ({
  customer: appSelectors.customer(state),
});

export const CountryField = connect(mapStateToProps)(CountryField_);
