import React from 'react';
import StoreIcon from '@material-ui/icons/Store';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import InfoIcon from '@material-ui/icons/Info';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import EventIcon from '@material-ui/icons/Event';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AppConfig from 'config/app';

const ProfileView = (props) => {
  const {account, location} = props;
  // get API path and app url
  const apiUrl = AppConfig.apiRoot + '/activeoffer/';
  const appUrl = window.location.protocol + "//" + window.location.host ;
  const {id:accountId} = account;
  const {id:locationId} = location;
  const embedScript = 
 `<!-- PeopleCount Script Start -->
  <script type="text/javascript"> 
  function __PC_Offers(t,e){const o={init:()=>{},imageURL:"",offer:{},retrieve:()=>{console.log("retrieve");const n=new Headers({Authorization:"peoplecount"});fetch("${apiUrl}"+t+"/"+e,{headers:n}).then(function(t){return t.json()}).then(function(t){t?(console.log("[PeopleCount] Active offer found: ",t),this.offer=t,o.display(t)):console.log("[PeopleCount] No active offer found for this location and/or time slot")}).catch(function(t){console.warn("Something went wrong.",t)})},display:t=>{let e=document.createElement("div");e.setAttribute("style","position: fixed; z-index: 1010; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, .65);");let o=document.createElement("div");o.setAttribute("style","position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);width:500px;transition: all 300ms ease-in-out; z-index: 1011;");let i=document.createElement("img");i.setAttribute("src",t.imageURL),i.setAttribute("alt","loading..."),i.setAttribute("style","cursor:pointer;max-width:100%;width:500px;height:100%"),o.appendChild(i),e.addEventListener("click",()=>{e.remove(),o.remove()}),o.addEventListener("click",()=>{const{id:t,locationId:e,accountId:o}=this.offer;window.location.href="${appUrl}/cart?offerId="+t+"&locationId="+e+"&accountId="+o});let r=document.querySelector("body");r.appendChild(e),r.appendChild(o)},checkout:()=>{}};return o} window.onload=function(){__PC_Offers(${accountId},${locationId}).retrieve()}
  </script>
  <!-- PeopleCount Script End -->`;

//  `<!-- PeopleCount Script Start -->
//   <script type="text/javascript"> 
//     function __PC_Offers(e,t){const o={init:()=>{},imageURL:"",offer:{},retrieve:()=>{console.log("retrieve");const n=new Headers({Authorization:"peoplecount"});fetch('${apiUrl}'+'activeoffer/'+e+'/'+t,{headers:n}).then(function(e){return e.json()}).then(function(e){e?(console.log("[PeopleCount] Active offer found: ",e),this.offer=e,o.display(e)):console.log("[PeopleCount] No active offer found for this location and/or time slot")}).catch(function(e){console.warn("Something went wrong.",e)})},display:e=>{let t=document.createElement("div");t.setAttribute("style","position: fixed; z-index: 1010; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, .65);");let o=document.createElement("div");o.setAttribute("style","position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width:500px; height:auto; transition: all 300ms ease-in-out; z-index: 1011;");let n=document.createElement("img");n.setAttribute("src",e.imageURL),n.setAttribute("id","peopleCountAdImage"),n.setAttribute("style","cursor:pointer;max-width:100%"),o.appendChild(n),t.addEventListener("click",()=>{t.remove(),o.remove()}),o.addEventListener("click",()=>{const{id:e,locationId:t,accountId:o}=this.offer;window.location.href='${appUrl}'+'cart?offerId='+e+'&locationId='+t+'&accountId='+o});let i=document.querySelector("body");i.appendChild(t),i.appendChild(o)},checkout:()=>{}};return o} window.onload=function(){__PC_Offers(${accountId},${locationId}).retrieve()}; 
//   </script>
//   <!-- PeopleCount Script End -->`;

  console.log(account, location);
  const handleCopyClick = () => {
    toast.success('Code copied to clipboard');
  };
  return (
    <React.Fragment>
      <div className="contact-info">
        <div className="field">
          <div className="value"><StoreIcon className="mr-3" />{location.name}</div>
        </div>
        <div className="field">
          <div className="value"><LocationOnIcon className="mr-3" />{location.address}</div>
        </div>
        <div className="field">
          <div className="value"><PhoneIcon className="mr-3" />{location.phone}</div>
        </div>
        <div className="field">
          <div className="value"><InfoIcon className="mr-3" />{location.notes}</div>
        </div>
        <div className="field">
          <div className="value"><AlternateEmailIcon className="mr-3" />{account.email}</div>
        </div>
      </div>
      <div className="contact-info mb-6">
        <Typography variant="h6">Script to Embed</Typography>
        <Typography variant="caption" className="mr-4">Embed this script at the beggining of the &lt;body&gt; tag in your homepage </Typography>
        <CopyToClipboard text={embedScript} onCopy={handleCopyClick} >
          <Button>COPY</Button>
        </CopyToClipboard>
        <TextField
          multiline
          readonly
          fullWidth
          value={embedScript}
          variant="outlined"
        />
      </div>
    </React.Fragment>
  )
}
;
export default ProfileView;