import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { TextField, MenuItem } from '@material-ui/core';
import { US_STATES } from 'config/usStates';
import { isRequired } from 'service/utility/errorMessages';

export class StateField extends Component {
  static propTypes = {
    error: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    error: '',
    label: 'State',
    required: false,
    value: '',
  };

  constructor(props) {
    super(props);

    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    this.state = {
      blurred: false,
    };

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value && required) return isRequired(label);

    return null;
  };

  handleLeaveFocus = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (value) => {
    const { onChange } = this.props;
    const error = this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const { error, onChange, label, required, value: rawValue, ...rest } = this.props;
    const value = rawValue || '';
    const { blurred } = this.state;

    return (
      <TextField
        select
        error={blurred && Boolean(error)}
        helperText={error}
        required={required}
        label={label}
        value={value}
        onChange={(e) => this.handleChangeSelf(e.target.value)}
        onBlur={this.handleLeaveFocus}
        variant="outlined"
        classes={{ root: 'text-field state-field' }}
        margin="dense"
        {...rest}
      >
        {US_STATES.map((option) => (
          <MenuItem
            key={option}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}
