import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Radio, Dialog, DialogTitle, DialogContent, DialogActions, Button, Link as ButtonLink } from '@material-ui/core';

import { cartSelectors } from 'app/cart';
import { appActions, appSelectors } from 'app/redux';
import { ALL_LOCATIONS } from 'app/redux/reducers/location';
import LocationDetails from 'common/LocationDetails';
import { Spinner } from 'common/statusIndicators';
import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';


const LocationDialog = (props) => {
  const [loadingLocations, setLoadingLocations] = React.useState(true);
  const [selectedLocationId, setSelectedLocationId] = React.useState(props.location.id);
  const [locations, setLocations] = React.useState([]);
  const [locationMap, setLocationMap] = React.useState({});


  React.useEffect(() => {
    let mounted = true;

    const loadLocations = async () => {
      try {
        const { data: locations_ } = await API.getLocations();
        const locationMap_ = locations_.reduce((a, b) => {
          a[b.id] = b;

          return a;
        }, {});

        console.log('LocationDialog; locations: ', locations_);

        if (mounted) {
          setLocations(locations_);
          setLocationMap(locationMap_);
        }
      } catch (error) {
        const errorMessage = getErrorMessage(error);

        console.log('LocationDialog; getLocations error: ');
        console.log(errorMessage);

        toast.error(errorMessage);
      } finally {
        if (mounted) {
          setLoadingLocations(false);
        }
      }
    };

    loadLocations();

    return () => mounted = false;
  }, []);


  const handleLocationChange = (locationId) => {
    setSelectedLocationId(Number(locationId));
  };

  const handleSaveAndClose = () => {
    const selectedLocation = selectedLocationId ? locationMap[selectedLocationId] : ALL_LOCATIONS;

    props.changeLocation(selectedLocation);

    if (props.onLocationSelect) {
      props.onLocationSelect(selectedLocation);
    }

    props.onClose();
  };


  return (
    <Dialog
      open={props.open}
      scroll="paper"
    >
      {Boolean(props.location.id) && (
        <DialogTitle
          disableTypography
          classes={{ root: 'pb-0' }}
        >
          <span className="font-weight-thick text-small">
            {'Current Location'}
          </span>
          <ul className="border-bottom border-thick list-group list-group-flush w-100">
            <li className="list-group-item border-top w-100">
              <LocationDetails location={props.location} />
            </li>
          </ul>
        </DialogTitle>
      )}
      <DialogContent classes={{ root: 'pt-0 pb-0 d-flex flex-column justify-content-start align-items-start' }}>
        <div className="w-100 pt-3">
          <span className="font-weight-thick text-small">
            {props.location.id ? 'Choose New Location' : `Hello! Choose a Location you want to ${props.firstAction} at:`}
          </span>
        </div>
        <div className="border-top w-100">
          <ul className="list-group list-group-flush w-100">
            {loadingLocations ? (
              <Spinner />
            ) : locations.map((location, idx) => (
              <li
                key={idx}
                className="list-group-item list-group-item-action d-flex flex-row justify-content-between align-items-start pr-0"
                onClick={() => handleLocationChange(location.id)}
              >
                <div>
                  <LocationDetails location={location} />
                </div>
                <div>
                  <Radio
                    checked={selectedLocationId === location.id}
                    onChange={() => handleLocationChange(location.id)}
                    value={location.id}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </DialogContent>
      <DialogActions
        disableSpacing
        classes={{ root: 'pt-0 d-flex flex-column justify-content-start align-items-start' }}
      >
        <div className="border-top w-100 d-flex flex-row justify-content-start align-items-start pt-2 pl-3 pb-3">
          {props.cart && (
            <React.Fragment>
              <span className="font-weight-thick text-small">
                {'Warning:\u00A0'}
              </span>
              <span className="text-small">
                {'If you change your Location, your current cart will no longer be valid.'}
              </span>
            </React.Fragment>
          )}
        </div>
        <div className="w-100 cml-3 pt-3 d-flex flex-row justify-content-end align-items-center">
          <ButtonLink
            component="button"
            onClick={props.onClose}
          >
            {'Cancel'}
          </ButtonLink>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSaveAndClose}
            disabled={!selectedLocationId}
          >
            {'Save'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

LocationDialog.propTypes = {
  cart: PropTypes.object,
  changeLocation: PropTypes.func.isRequired,
  firstAction: PropTypes.string,
  location: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onLocationSelect: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

LocationDialog.defaultProps = {
  firstAction: 'shop',
};


const mapStateToProps = (state) => ({
  cart: cartSelectors.cartSelector(state),
  location: appSelectors.location(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeLocation: (location) => dispatch(appActions.changeLocation(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationDialog);
