import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Avatar } from './Avatar';

export class ProductAvatar extends Component {
  static propTypes = {
    size: PropTypes.string,
    src: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    size: 'normal',
    src: '',
  }

  render() {
    const { src, size, onClick } = this.props;

    return (
      <Avatar
        className="product-avatar"
        size={size}
        src={src}
        onClick={onClick}
      />
    );
  }
}

