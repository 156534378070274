export const formatAddress = (o) => (
  Boolean(o) && Boolean(o.address1)
    ? `${o.address1}${o.address2 ? `, ${o.address2}` : ''}${o.city ? `, ${o.city}` : ''}${o.state ? `, ${o.state}` : ''}${o.postalCode ? ` ${o.postalCode}` : ''}`
    : '--'
);

export const formatName = (o) => (o ? `${o.firstName} ${o.lastName}` : '');

export const formatInitials = (o) => (o ? `${o.firstName[0]} ${o.lastName[0]}` : '');

export const testAgainst = (searchString, string) => RegExp(searchString.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i').test(string);

export const capital = (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
