import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { appSelectors } from 'app/redux';
import { Spinner } from 'common/statusIndicators';


const CartTotals = (props) => {
  const {
    subtotal = 0,
    totalDiscount = 0,
    taxRate = 0,
    totalTax = 0,
    total = 0,
  } = props.totals;

  return (
    <div className="d-flex flex-column justify-content-start align-items-start">
      <span className="font-weight-thick text-small">
        {'Total Amount'}
      </span>
      <div className="border-top w-100 d-flex flex-column justify-content-start align-items-start">
        {props.loading ? (
          <Spinner />
        ) : (
          <div className="row no-gutters w-100">
            <div className="col-md-8 offset-md-4">
              <table className="table">
                <tbody>
                  <tr className="text-right">
                    <td className="border-top-0">
                      {'Subtotal'}
                    </td>
                    <td className="border-top-0">
                      {subtotal}
                    </td>
                  </tr>
                  {totalDiscount !== 0 && (
                    <tr className="text-right">
                      <td>
                        {'Total Discounts'}
                      </td>
                      <td>
                        {totalDiscount}
                      </td>
                    </tr>
                  )}
                  <tr className="text-right">
                    <td>
                      {'Total'}
                    </td>
                    <td>
                      {total}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CartTotals.propTypes = {
  account: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  totals: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  account: appSelectors.account(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CartTotals);
