import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input/max';
import { TextField } from '@material-ui/core';
import { appSelectors } from 'app/redux';
import { getCurrentCountry } from 'service/utility';
import { isRequired } from 'service/utility/errorMessages';

const getCountryConfig = (currentCountry, value) => {
  const countryConfig = {};

  if (value === '' || value === null || typeof value === 'undefined') {
    countryConfig.country = currentCountry;
    countryConfig.isLocalNumber = false;
  } else {
    const parsed = parsePhoneNumber(value);

    if (parsed) {
      if (parsed.country) {
        countryConfig.country = parsed.country;
        countryConfig.isLocalNumber = true;
      } else {
        countryConfig.country = currentCountry;
        countryConfig.isLocalNumber = false;
      }
    }
  }

  return countryConfig;
};

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, value, currentCountry, ...rest } = props;
  const { country, isLocalNumber } = getCountryConfig(currentCountry, value);

  return (
    <PhoneInput
      value={value}
      country={country}
      displayInitialValueAsLocalNumber={isLocalNumber}
      limitMaxLength
      onChange={
        (phone) => onChange({
          target: {
            value: phone,
          },
        })
      }
      {...rest}
    />
  );
};

NumberFormatCustom.propTypes = {
  currentCountry: PropTypes.string,
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

class PhoneField_ extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    validate: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    autoFocus: false,
    error: '',
    fullWidth: false,
    label: 'Phone',
    required: false,
    value: '',
  }

  constructor(props) {
    super(props);

    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    this.state = {
      blurred: false,
    };

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  validateSelf = (value) => {
    const { validate, required, label } = this.props;

    if (!value && required) return isRequired(label);

    return validate ? validate(value) : null;
  };

  handleLeaveFocus = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (value) => {
    const { onChange } = this.props;
    const error = this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const { autoFocus, dispatch, error, fullWidth, onChange, label, required, validate, value: rawValue, ...rest } = this.props;
    const { blurred } = this.state;

    const value = rawValue || '';
    const currentCountry = 'US';

    return (
      <TextField
        error={blurred && Boolean(error)}
        helperText={error}
        required={required}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        label={label}
        value={value}
        onChange={(e) => this.handleChangeSelf(e.target.value)}
        onBlur={this.handleLeaveFocus}
        variant="standard"
        classes={{ root: 'number-field phone-field' }}
        margin="dense"
        InputProps={{
          inputComponent: NumberFormatCustom,
          inputProps: {
            currentCountry,
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
      />
    );
  }
}

// const mapStateToProps = (state) => ({
//   customer: appSelectors.customer(state),
// });

export const PhoneField = connect(null)(PhoneField_);
