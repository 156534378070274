import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Fab } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

export class RemoveFab extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  handleClick = (event) => {
    event.stopPropagation();
    this.props.onClick();
  };

  render() {
    return (
      <Fab
        className="remove-fab"
        onClick={this.handleClick}
      >
        <Clear />
      </Fab>
    );
  }
}
