import React from 'react';
import { FormControl, InputLabel, Input, FormHelperText, Typography, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  labelRoot: {
  },
  labelShrink: {
  },
  inputRoot: {
  },
  inputInput: {
  },
  helperTextRoot: {
  },
  root: {
  }
});

export const StandardTextField = (props) => {
  const classes = useStyles();
  return (
    <FormControl>
      <InputLabel shrink={true} classes={{ root: classes.labelRoot, shrink: classes.labelShrink }}>
        {props.label}
      </InputLabel>
      <Input {...props} />
      <Typography variant="caption" >{props.helpertext}</Typography>
    </FormControl>
  );
}

