import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, SelectableInput, TimeField } from 'common/formFields';
import MUITextField  from '@material-ui/core/TextField';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Grid from '@material-ui/core/Grid';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
  caption: {
    marginLeft: '3rem',
    color: '#999999'
  },
  radioFormGroup: {
    marginLeft: '2rem',
    marginBottom: '1rem'
  },
  radioLabel: {
    marginTop: '1rem'
  },
  root: {
    marginBottom: '20px'
  },
  textFieldAddon: {
    position: 'relative',
    bottom: '-2.1em',
    left: '0.5em'
  }
}));

export const CapAdParams = ({offerModel,setOfferModel}) => {
  const classes = useStyles();
  const [displaySalePrice,setDisplaySalePrice] = React.useState(null);
  
  React.useEffect(() => {
    const dsp = offerModel.salePrice / 100;
    setDisplaySalePrice(dsp);
  }, [offerModel]);
  
  const handleInputChange = (field) => (event) => {
    if(field === 'salePrice') {
      const v = event.target.value * 100;
      offerModel = {...offerModel, [field]: v};
    } else {
      offerModel = {...offerModel, [field]: event.target.value};
    }
    setOfferModel(offerModel);
  };

  const handleCheckboxChange = (field, value) => (event) => {
    offerModel = {...offerModel, [field]: event.target.value};
    setOfferModel(offerModel);
  }

  

  const handleStartTimeChange = (date) => {
    offerModel = {...offerModel, startTime: date};
    setOfferModel(offerModel);
  }
  const handleEndTimeChange = (date) => {
    offerModel = {...offerModel, endTime: date};
    setOfferModel(offerModel);
  }
  const handleStartDateChange = (date) => {
    offerModel = {...offerModel, startDate: date};
    setOfferModel(offerModel);
  }
  const handleEndDateChange = (date) => {
    offerModel = {...offerModel, endDate: date};
    setOfferModel(offerModel);
  }

  return (
    <React.Fragment>
    <Typography variant="h6">Capacity Based Ad</Typography>
    <form className="offer-form">
      <div>
        <TextField label="Occupancy %" value={offerModel.minOccupancy}
          onChange={handleInputChange('minOccupancy')} size="small" 
          helpertext={`Ad will be triggered when occupancy is ${offerModel.minOccupancy}% or below.`}/>
          <span className={classes.textFieldAddon}>% or below</span>
      </div>
      <div>
        <TextField label="Time of Below Occupancy" value={offerModel.timeBelowOccupancy}
          onChange={handleInputChange('timeBelowOccupancy')} size="small" 
          helpertext={`Ad will be triggered when occupancy is ${offerModel.minOccupancy}% or below. for ${offerModel.timeBelowOccupancy} minutes or longer`} />
        <span className={classes.textFieldAddon}>minutes or longer</span>
      </div>
      <div className={classes.root}>
        <div className="form-control-label mb-3">Date range</div>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Start Date"
                value={offerModel.startDate}
                onChange={handleStartDateChange}
                animateYearScrolling
                inputVariant="outlined"
                format="MM/dd/yyyy"
                />
              </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="End Date"
                value={offerModel.endDate}
                onChange={handleEndDateChange}
                animateYearScrolling
                inputVariant="outlined"
                format="MM/dd/yyyy"
                />
              </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </div>
      <div className={classes.root}>
        <div className="form-control-label">Times</div>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Start Time"
              value={offerModel.startTime}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputVariant="outlined"
              minutesStep={5}
              onChange={handleStartTimeChange}
            />
            </MuiPickersUtilsProvider>
              {/* <TimeField label="Start time" value={offerModel.startDate} type="time"
               inputProps={{step:300}}
              onChange={handleInputChange('startTime')} size="small" /> */}
            </Grid>
            <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="End Time"
              value={offerModel.endTime}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputVariant="outlined"
              minutesStep={5}
              onChange={handleEndTimeChange}
            />
            </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        <Typography variant="caption" className={classes.caption}>These are the times at which the ad could be displayed</Typography>
      </div>
      <div>
        <TextField label="Number of Offers" value={offerModel.totalAvailable}
          onChange={handleInputChange('totalAvailable')} size="small" 
          helpertext={`Only ${offerModel.totalAvailable} offers will be issued every time is triggered.`}/>
      </div>
      <div>
        <TextField label="Sale Price" value={displaySalePrice}
          size="small" onChange={handleInputChange('salePrice')} 
          helpertext={`Please note that the sale price must be $5 or more`}/>
      </div>
      <div>
        <TextField label="Tax" value={offerModel.tax}
          size="small" onChange={handleInputChange('tax')} 
          helpertext={``}/>
      </div>
    </form>
    </React.Fragment>
  )
}