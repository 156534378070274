import { createReducer } from 'service/utility';

import { SET_LOCATION, SET_LOCATIONS } from '../actions';
import { SIGN_OUT } from 'app/auth/redux/actions';

export const ALL_LOCATIONS = { id: 0, name: 'All Locations' };

const initState = {};

export const location = createReducer({}, {
  [SET_LOCATION]: (state, { location: location_ }) => location_,
  [SIGN_OUT]: () => null,
});
export const locations = createReducer([], {
  [SET_LOCATIONS]: (state, { locations: locations_ }) => locations_,
  [SIGN_OUT]: () => null,
});
