import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom'
import { ROUTE_NAMES } from 'config/routeNames';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  topbarLogo: {
    height: '40px'
  }
}));

export const AppTitle = (props) => {
  const classes = useStyles();
  // const location = useLocation();
  // console.log('location: ', location.pathname);
  // console.log('route name: ', ROUTE_NAMES[location.pathname]);
  // const text = ROUTE_NAMES[location.pathname] || '';
  // const text = 'PeopleCount';


  return (
    <Typography variant="h6" color="inherit" className={classes.title}>
      <img className={classes.topbarLogo} src={require('assets/pc-logo-wb.svg')} alt="PeopleCount" />
    </Typography>
  );
}

