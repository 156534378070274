export const APP_NAME = 'PeopleCount WebApp';
const APP_CONFIG = {
  localhost: {
    apiRoot: 'http://localhost:3000',
    // apiRoot: 'https://ryt7pxlc5hexecute-api.us-east-1.amazonaws.com/testing',
    firebase: {
      apiKey: "AIzaSyB70in-wUc8qPVzDMyy3kQU8Wkkx-G3ds4",
      authDomain: "peoplecount-e360c.firebaseapp.com",
      databaseURL: "https://peoplecount-e360c.firebaseio.com",
      projectId: "peoplecount-e360c",
      storageBucket: "peoplecount-e360c.appspot.com",
      messagingSenderId: "195052648063",
      appId: "1:195052648063:web:7c41a1840 =624732402f14a",
      measurementId: "G-H3FM2YE0XZ"
    },
  },
  testing: {
    // apiRoot: 'https://fwv92hcknl.execute-api.us-east-1.amazonaws.com/staging/',
    apiRoot: 'https://ryt7pxlc5h.execute-api.us-east-1.amazonaws.com/testing',
    firebase: {
      apiKey: "AIzaSyB70in-wUc8qPVzDMyy3kQU8Wkkx-G3ds4",
      authDomain: "peoplecount-e360c.firebaseapp.com",
      databaseURL: "https://peoplecount-e360c.firebaseio.com",
      projectId: "peoplecount-e360c",
      storageBucket: "peoplecount-e360c.appspot.com",
      messagingSenderId: "195052648063",
      appId: "1:195052648063:web:7c41a1840624732402f14a",
      measurementId: "G-H3FM2YE0XZ"
    },
  },
  staging: {
    apiRoot: 'https://fwv92hcknl.execute-api.us-east-1.amazonaws.com/staging',
    firebase: {
      apiKey: "AIzaSyB70in-wUc8qPVzDMyy3kQU8Wkkx-G3ds4",
      authDomain: "peoplecount-e360c.firebaseapp.com",
      databaseURL: "https://peoplecount-e360c.firebaseio.com",
      projectId: "peoplecount-e360c",
      storageBucket: "peoplecount-e360c.appspot.com",
      messagingSenderId: "195052648063",
      appId: "1:195052648063:web:7c41a1840624732402f14a",
      measurementId: "G-H3FM2YE0XZ"
    },
  },
  production: {
    apiRoot: 'https://3jvi3266bd.execute-api.us-east-1.amazonaws.com/prod',
    firebase: {
      apiKey: "AIzaSyB70in-wUc8qPVzDMyy3kQU8Wkkx-G3ds4",
      authDomain: "peoplecount-e360c.firebaseapp.com",
      databaseURL: "https://peoplecount-e360c.firebaseio.com",
      projectId: "peoplecount-e360c",
      storageBucket: "peoplecount-e360c.appspot.com",
      messagingSenderId: "195052648063",
      appId: "1:195052648063:web:7c41a1840624732402f14a",
      measurementId: "G-H3FM2YE0XZ"
    },
  },
};

const getAppConfig = () => {
  // NODE_ENV is being defaulted to production by an unknown force :(
  const releaseChannel = process.env.REACT_APP_ENV;

  console.log('env ',process.env);

  if (typeof releaseChannel === 'undefined') return APP_CONFIG.testing;
  if (releaseChannel === 'testing') return APP_CONFIG.testing;
  if (releaseChannel === 'staging') return APP_CONFIG.staging;
  if (releaseChannel === 'production') return APP_CONFIG.production;

  return APP_CONFIG.localhost;
};

const AppConfig = getAppConfig();

export default AppConfig;