import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { MenuList, MenuItem, Button, ListItem, ListItemText, Popper, Grow, Paper, ClickAwayListener } from '@material-ui/core';
import Business from '@material-ui/icons/Business';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import { appActions, appSelectors } from 'app/redux';
import * as API from 'service/api';
import { formatAddress } from 'service/utility/stringFormatters';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  whiteText: {
    color: '#ffffff',
    fontSize: '1rem',
    fontWeight: 400,
    marginLeft: '0.5rem'
  },
  almostWhiteText: {
    color: '#fafafa',
    fontSize: '0.75rem',
    fontWeight: 400,
    marginLeft: '0.5rem'
  }
}));

const LocationStripe_ = (props) => {
  let anchorEl = React.useRef(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);  
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);  
  const [buttonWidth, setButtonWidth] = React.useState(null);  
  const classes = useStyles();


  React.useEffect(() => {
    setTimeout(() => {
      // setButtonWidth(window.getComputedStyle(anchorEl).getPropertyValue('width'))
    })
  })


  const handleToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const handleCloseMenu = (event) => {
    if (anchorEl.contains(event.target)) return;
    setIsMenuOpen(false);

  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    setIsMenuOpen(false);
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  }

  const hasCardOptions = () => {
    // return true if the account is connected to stripe]
    return props.account && props.account.stripeAccountId; 
  };

  const handleStripeOauth = () => {
    API.authorize(props.account.id, `${window.location.origin}/stripe-callback?retUrl=${window.location.pathname}`).then(
      ({ data }) => {
        window.location = data;
      }
    );
  };

    return (
      <div className="location-selector">
        <Button
          buttonRef={(node) => anchorEl = node}
          onClick={handleToggle}
          size="small"
          color="inherit"
          classes={{
            root: classNames('location-selector-button', { pushed: isMenuOpen }),
            label: 'location-selector-button-inner',
          }}
        >
          <Business />
          <ListItemText
            primary={props.location ? props.location.name : 'Loading location'}
            secondary={hasCardOptions() ? 'Connected to Stripe' : 'No Stripe Account'}
            classes={{
              primary: classes.whiteText,
              secondary: classes.almostWhiteText,
            }}
          />
          <KeyboardArrowDown fontSize="large" />
        </Button>
        <Popper
          open={isMenuOpen}
          anchorEl={anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                width: buttonWidth,
              }}
            >
              <Paper
                square
                classes={{ root: 'menu-paper' }}
              >
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList classes={{ root: 'menu-list' }}>
                  <MenuItem
                    onClick={handleStripeOauth}
                    classes={{ root: 'menu-item' }}
                  >
                    {hasCardOptions() ? 'Refresh Stripe' : 'Connect Stripe'}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
}

LocationStripe_.propTypes = {
  location: PropTypes.object,
  account: PropTypes.object,
};

const mapStateToProps = (state) => ({
  location: appSelectors.location(state),
  account: appSelectors.account(state),
});

const mapDispatchToProps = (dispatch) => ({
});

export const LocationStripe = connect(mapStateToProps, mapDispatchToProps)(LocationStripe_);
