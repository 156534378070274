import { formatPhoneNumber } from 'react-phone-number-input';

export const viewToRaw = (phoneNumber) => (phoneNumber[0] === '+' ? phoneNumber : `+1${phoneNumber}`);

export const rawToView = (phoneNumber) => (phoneNumber[0] === '+' ? phoneNumber.slice(2) : phoneNumber);

export const formatPhone = (phoneNumber) => formatPhoneNumber(phoneNumber);
// if (!phoneNumber) return '';

// const newNumber = phoneNumber[0] === '+' ? rawToView(phoneNumber) : phoneNumber;
// const areaCode = newNumber.slice(0, 3);
// const firstSlice = newNumber.slice(3, 6);
// const secondSlice = newNumber.slice(6, 10);

// return `(${areaCode}) ${firstSlice}-${secondSlice}`;
