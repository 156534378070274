export const DATATYPES = {
  id: {
    name: 'id',
    jsType: 'number',
  },
  string: {
    name: 'string',
    jsType: 'string',
  },
  multilineString: {
    name: 'multilineString',
    jsType: 'string',
  },
  picklist: {
    name: 'picklist',
    jsType: 'string',
  },
  phone: {
    name: 'phone',
    jsType: 'string',
  },
  email: {
    name: 'email',
    jsType: 'string',
  },
  currency: {
    name: 'currency',
    jsType: 'number',
  },
  number: {
    name: 'number',
    jsType: 'number',
  },
  percentage: {
    name: 'percentage',
    jsType: 'number',
  },
  boolean: {
    name: 'boolean',
    jsType: 'number',
  },
  weekDay: {
    name: 'weekDay',
    jsType: 'number',
  },
  date: {
    name: 'date',
    jsType: 'date',
  },
  datetime: {
    name: 'datetime',
    jsType: 'date',
  },
  array: {
    name: 'array',
    jsType: 'array',
  },
  relatedCheckbox: {
    name: 'relatedCheckbox',
    jsType: null,
  },
  state: {
    name: 'state',
    jsType: 'string',
  },
  country: {
    name: 'country',
    jsType: 'string',
  },
  postalCode: {
    name: 'postalCode',
    jsType: 'number',
  },
  address: {
    name: 'address',
    jsType: 'object',
  },
  relatedObject: {
    name: 'relatedObject',
    jsType: 'object',
  },
  name: {
    name: 'name',
    jsType: 'string',
  },
};
