import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { ImageControlView } from './ImageControlView';

export class ImageControl extends Component {
  static propTypes = {
    avatar: PropTypes.bool,
    imageURL: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    size: PropTypes.number,
    uploading: PropTypes.bool,
  };

  static defaultProps = {
    avatar: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  handleNewImage = (img64) => {
    this.props.onSubmit(img64);
    this.setState({ isModalOpen: false });
  };

  handleChangeImage = () => this.setState({ isModalOpen: true });

  handleCancelModal = () => this.setState({ isModalOpen: false });

  render() {
    const { avatar, imageURL, onDelete, uploading, size } = this.props;
    const { isModalOpen } = this.state;

    return (
      <ImageControlView
        avatar={avatar}
        imageURL={imageURL}
        onDelete={onDelete}
        isModalOpen={isModalOpen}
        uploading={uploading}
        size={size}
        handleNewImage={this.handleNewImage}
        handleChangeImage={this.handleChangeImage}
        handleCancelModal={this.handleCancelModal}
      />
    );
  }
}
