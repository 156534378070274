import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { ImageDialogView } from './ImageDialogView';

export class ImageDialog extends Component {
  static propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleNewImage: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.freshState = {
      mode: 'upload',
      uploadedFile: null,
      uploaded64: null,
    };

    this.state = this.freshState;
  }

  resetState = () => this.setState(this.freshState);

  cancelSelf = () => this.props.handleCancel();

  handleTabChange = (e, val) => this.setState({ mode: val });

  handleSave = () => {
    const { uploaded64 } = this.state;

    if (uploaded64) {
      this.props.handleNewImage(uploaded64);
    }
  }

  handleNewImageFromWebcam = (newImage) => this.props.handleNewImage(newImage);

  handleNewUploadedFiles = (newFiles) => {
    // take first file. Uploader can return multiple files,
    const file = newFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const fileAsBase64 = reader.result;

      this.setState({
        uploadedFile: file,
        uploaded64: fileAsBase64,
      });
    };

    reader.readAsDataURL(file);
  }

  render() {
    const { isOpen } = this.props;
    const { mode, uploaded64 } = this.state;

    return (
      <ImageDialogView
        isOpen={isOpen}
        mode={mode}
        uploaded64={uploaded64}
        handleTabChange={this.handleTabChange}
        cancelSelf={this.cancelSelf}
        handleNewUploadedFiles={this.handleNewUploadedFiles}
        handleNewImageFromWebcam={this.handleNewImageFromWebcam}
        handleSave={this.handleSave}
        resetState={this.resetState}
      />
    );
  }
}
