import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
// import { throttle } from 'lodash';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { isRequired, noZero, noNegative, isInvalid } from 'service/utility/errorMessages';

class NumberFormatCustom extends Component {
  static propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  // componentDidMount = () => {
  //   this.onValueChange = throttle(this.onValueChange_, 200);
  // };

  // onValueChange_ = (values) => {
  onValueChange = (values) => {
    this.props.onChange({
      target: {
        value: values.value,
      },
    });
  };

  render () {
    const { inputRef, onChange, ...rest } = this.props;

    return (
      <NumberFormat
        {...rest}
        getInputRef={inputRef}
        onValueChange={this.onValueChange}
      />
    );
  }
}

export class NumberField extends Component {
  static propTypes = {
    allowDecimals: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowZero: PropTypes.bool,
    autoFocus: PropTypes.bool,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    margin: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    variant: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    allowDecimals: true,
    allowNegative: true,
    allowZero: true,
    autoFocus: false,
    error: '',
    fullWidth: false,
    inputProps: {},
    label: '',
    margin: 'dense',
    required: false,
    value: null,
    variant: 'outlined',
  };

  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount = () => {
    const { value, onChange } = this.props;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  };

  componentWillUnmount = () => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  };    
  
  validateSelf = (value) => {
    const { allowNegative, allowZero, validate, required, label } = this.props;

    if (value === null && required) return isRequired(label);
    if (value === 0 && !allowZero) return noZero(label);
    if (value < 0 && !allowNegative) return noNegative(label);

    return validate ? validate(value) : null;
  };

  handleLeaveFocus = () => {
    const { value, onChange } = this.props;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (rawValue) => {
    const { label, onChange } = this.props;
    const value = rawValue === '' || rawValue === null ? null : Number(rawValue);
    const error = isNaN(value) ? isInvalid(label) : this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const { allowDecimals, allowNegative, allowZero, error, onChange, validate, inputProps, ...rest } = this.props;
    const { blurred } = this.state;

    return (
      <TextField
        error={blurred && Boolean(error)}
        helperText={error}
        onChange={(e) => this.handleChangeSelf(e.target.value)}
        onBlur={this.handleLeaveFocus}
        classes={{ root: 'number-field' }}
        InputProps={{
          inputProps: {
            ...(allowDecimals ? {} : { decimalScale: 0 }),
            ...inputProps,
          },
          inputComponent: NumberFormatCustom,
        }}
        {...rest}
      />
    );
  }
}