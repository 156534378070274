import { toast } from 'react-toastify';
import { take, call, put } from 'redux-saga/effects';
import { appActions } from 'app/redux';
import * as API from 'service/api';
import { getErrorMessage } from 'service/utility';
import { SIGN_IN, setCurrentUser } from '../actions';

export function *signInSaga() {
  while (true) {
    const { user } = yield take(SIGN_IN);

    yield put(setCurrentUser(user));

    // Account
    try {
      const { data } = yield call(API.getAccount);
      if(!data) toast.error('No account found for this user');
      yield put(appActions.setAccount(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.getAccount error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    }

  // Locations
    try {
      const { data } = yield call(API.getLocations);
      if(data && data[0]) {
        yield put(appActions.setLocation(data[0]));  
      }
      yield put(appActions.setLocations(data));
    } catch (error) {
      console.error(error);
      const errorMessage = getErrorMessage(error);

      console.log('API.getLocations error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    }

  }
}
