import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import { createHashHistory } from 'history';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { getStore } from 'app/redux';
import APP_CONFIG from 'config/app';
import THEME_CONFIG from 'config/theme';
import TOAST_CONFIG from 'config/toast';
import { saveState } from 'service/storage';

import { Initializer } from './Initializer';
import Layout from './Layout';
import Routes from './Routes';

import './app.scss';

toast.configure(TOAST_CONFIG);

const history = createHashHistory();
const store = getStore(history);
const theme = createMuiTheme(THEME_CONFIG);

store.subscribe(() => {
  saveState(store.getState());
});


const App = () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
            <Initializer>
              <Layout>
                <Routes />
              </Layout>
            </Initializer>
        </BrowserRouter>
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;
