import axios from 'axios';
import AppConfig from 'config/app';
import { getIdToken } from 'service/firebase';
import { appSelectors } from 'app/redux';
import { createHashHistory } from 'history';
import { getStore } from 'app/redux';

export const getNamespace = () => {
  // return 'peoplecount';
  // TODO check if the app crashes on login, when the account is not yet there
  // it does crash
  const history = createHashHistory();
  const store = getStore(history);
  const state = store.getState();
  const account = appSelectors.account(state);
  if(account) return account.shortCode;
  return 'peoplecount'
  // return account.shortCode;
}

export const initAxios = async () => {
  const token = await getIdToken();
  const bearer = getNamespace();
  console.log('initAxios -> ', `${AppConfig.apiRoot}`);
  console.log(`Bearer: ${bearer}` );
  const instance = axios.create({
    baseURL: `${AppConfig.apiRoot}`,
    headers: { Authorization: `${bearer} ${token}` },
  });

  instance.interceptors.request.use((config) => {
    console.log(`Making axios API call: ${config.method.toUpperCase()} ${config.url}`);
    return config;
  });

  return instance;
};