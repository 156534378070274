import React, {Fragment, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';

import { getTodaysOffers } from 'service/api';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.date}</TableCell>
        <TableCell>{row.offersClaimed}</TableCell>
        <TableCell>{row.notes}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Order #</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.orders.map((orderRow) => (
                    <TableRow key={orderRow.orderNumber}>
                      <TableCell component="th" scope="row">
                        {orderRow.orderNumber}
                      </TableCell>
                      <TableCell>{orderRow.customerName}</TableCell>
                      <TableCell>{orderRow.customerEmail}</TableCell>
                      <TableCell>{orderRow.customerPhone}</TableCell>
                      <TableCell>{orderRow.status}</TableCell>
                      <TableCell><Button>CHECK IN</Button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    offersClaimed: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        orderNumber: PropTypes.number.isRequired,
        customerName: PropTypes.string.isRequired,
        customerEmail: PropTypes.string.isRequired,
        customerPhone: PropTypes.string.isRequired,
        status: PropTypes.string,
      }),
    ).isRequired
  }).isRequired,
};

const rows = [
  createData('Day Pass Offer', 'This is an offer that reduces the price of a Day Pass for the first 5 people to claim the offer.', 
    '07/07/2020', '3/5', 'If the person arrives in the gym 5-10 minutes pass the deadline, feel free to give them the discount'),
  createData('New Gym Membership', 'This is an offer thar reduces the price of a Gym Membership by 50% for the first month', 
    '07/06/2020', '8/10', 'If the person arrives in the gym 5-10 minutes pass the deadline, feel free to give them the discount'),
  createData('70% off Order', 'This is an offer thar reduces the price of an offer by 70%', 
    '07/05/2020', '8/10', 'If the person arrives in the gym 5-10 minutes pass the deadline, feel free to give them the discount'),
  createData('Cert. Class Discount', 'Discounts Certification Classes by 50%', 
    '07/04/2020', '8/10', 'If the person arrives in the gym 5-10 minutes pass the deadline, feel free to give them the discount'),
  createData('New Gym Membership', 'This is an offer thar reduces the price of a Gym Membership by 50% for the first month', 
    '07/03/2020', '8/10', 'If the person arrives in the gym 5-10 minutes pass the deadline, feel free to give them the discount'),
];

function createData(name, description, date, offersClaimed, notes) {
  return {
    id: Math.floor(Math.random() * Math.floor(999999)),
    name,
    description,
    date,
    offersClaimed,
    notes,
    orders:
      [{
        "orderNumber": Math.floor(Math.random() * Math.floor(999999)),
        "customerName": "Laurella Simonot",
        "customerEmail": "lsimonot0@state.gov",
        "customerPhone": "808 275 6187",
        "status": "claimed today at 08:35am "
      }, {
        "orderNumber": Math.floor(Math.random() * Math.floor(999999)),
        "customerName": "Eudora Keers",
        "customerEmail": "ekeers1@mayoclinic.com",
        "customerPhone": "796 378 9627",
        "status": "redeemed today at 9:34am"
      }, {
        "orderNumber": Math.floor(Math.random() * Math.floor(999999)),
        "customerName": "Sargent Ishaki",
        "customerEmail": "sishaki2@wikispaces.com",
        "customerPhone": "318 904 2875",
        "status": "claimed today at 08:35am "
      }, {
        "orderNumber": Math.floor(Math.random() * Math.floor(999999)),
        "customerName": "Rosana Gennerich",
        "customerEmail": "rgennerich3@newyorker.com",
        "customerPhone": "961 340 7772",
        "status": "refunded today at 11:01am"
      }, {
        "orderNumber": Math.floor(Math.random() * Math.floor(999999)),
        "customerName": "Mara O'Carroll",
        "customerEmail": "mocarroll4@jimdo.com",
        "customerPhone": "295 690 8707",
        "status": "claimed today at 08:35am "
      }
    ],
  };
}


export const TodaysOffers = () => {
  useEffect(() => {
    let mounted = true;
  
    const loadOffers = async () => {
      try {
        const response = await getTodaysOffers();
        console.log(response);
        // const locations_ = Array.isArray(data) ? data.map((e) => ({ label: e.name, value: e.id })) : [];
  
        // console.log('Search; locations: ', locations_);
  
        // if (mounted) {
        //   setLocations(locations_);
  
        //   if (locations_.length) {
        //     setLocationId(locations_[0].value);
        //   }
        // }
      } catch (error) {
        // const errorMessage = getErrorMessage(error);
  
        // console.log('getLocations error: ');
        // console.log(errorMessage);
  
        // toast.error(errorMessage);
      } finally {
        if (mounted) {
          // setLoadingLocations(false);
        }
      }
    };
  
    loadOffers();
  
    return () => mounted = false;
  }, []);
  return (
    <Fragment>
    <Typography variant="h4" gutterBottom classes={{ h4: 'header-text' }}>Today's Offers <Button>Location Filter</Button></Typography>
    <Typography variant="h6" gutterBottom classes={{ root: 'header-underline-1x'}}>Claimed Offers</Typography>
    <TextField fullWidth variant="outlined" size="small" 
      InputProps={{
        startAdornment: ( <InputAdornment position="start"> <SearchIcon /> </InputAdornment> )
      }}
    />
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Date</TableCell>
            <TableCell># of Offers Claimed</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Fragment>
  );
}