import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { WebcamDialogView } from './WebcamDialogView';

export class WebcamDialog extends Component {
  static propTypes = {
    handleNewImage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      imgStr: null,
      croppedImgStr: null,
      captured: false,
      cropped: false,
    };
  }

  handleCapture = (imgStr) => this.setState({
    imgStr,
    captured: true,
  });

  handleCrop = (imgStr) => this.setState({
    croppedImgStr: imgStr,
    cropped: true,
  });

  handleRetake = () => this.setState({
    imgStr: null,
    captured: false,
    cropped: false,
  });

  handleSave = () => this.props.handleNewImage(this.state.croppedImgStr);

  render() {
    return (
      <WebcamDialogView
        {...this.state}
        handleCapture={this.handleCapture}
        handleRetake={this.handleRetake}
        handleCrop={this.handleCrop}
        handleSave={this.handleSave}
      />
    );
  }
}
