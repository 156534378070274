import { authActions } from 'app/auth';
import { createReducer } from 'service/utility';

import { SET_CART } from '../actions';

const { SIGN_OUT } = authActions;


const initialState = null;

const cart_ = createReducer(initialState, {
  [SET_CART]: (state, { cart: cart_ }) => cart_,
  [SIGN_OUT]: () => initialState,
});

export default cart_;
