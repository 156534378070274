import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, Button } from '@material-ui/core';

import { appActions, appSelectors } from 'app/redux';


const ConfirmActionDialog = (props) => {
  const { confirmActionDialog, actionConfirmed, actionCancelled } = props;
  const { isOpen, title, message, cancelLabel, confirmLabel, hideConfirm, hideCancel } = confirmActionDialog;

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="confirm"
      onClose={actionCancelled}
    >
      <DialogTitle>
        {title || 'Are you Sure?'}
      </DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {!hideCancel && (
          <Button
            onClick={actionCancelled}
          >
            {cancelLabel || 'Cancel'}
          </Button>
        )}
        {!hideConfirm && (
          <Button
            color="primary"
            onClick={actionConfirmed}
          >
            {confirmLabel || 'OK'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmActionDialog.propTypes = {
  actionCancelled: PropTypes.func.isRequired,
  actionConfirmed: PropTypes.func.isRequired,
  confirmActionDialog: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  confirmActionDialog: appSelectors.confirmActionDialog(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionConfirmed: () => dispatch(appActions.actionConfirmed()),
  actionCancelled: () => dispatch(appActions.actionCancelled()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmActionDialog);

