import { makeActionCreator } from 'service/utility';

export const SET_ACCOUNT = 'SET_ACCOUNT';
export const setAccount = makeActionCreator(SET_ACCOUNT, 'account');

export const SET_CUSTOMER = 'SET_CUSTOMER';
export const setCustomer = makeActionCreator(SET_CUSTOMER, 'customer');

export const SET_HOUSEHOLD = 'SET_HOUSEHOLD';
export const setHousehold = makeActionCreator(SET_HOUSEHOLD, 'household');

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const setLocations = makeActionCreator(SET_LOCATIONS, 'locations');

export const SET_LOCATION = 'SET_LOCATION';
export const setLocation = makeActionCreator(SET_LOCATION, 'location');

export const SET_SIDEBAR_EXPANDED = 'SET_SIDEBAR_EXPANDED';
export const setSidebarExpanded = makeActionCreator(SET_SIDEBAR_EXPANDED, 'isOpen');

export const SET_SIDEBAR_COLLAPSED = 'SET_SIDEBAR_COLLAPSED';
export const setSidebarCollapsed = makeActionCreator(SET_SIDEBAR_COLLAPSED, 'isOpen');

export const SET_NEW_OFFER = 'SET_NEW_OFFER';
export const setNewOffer = makeActionCreator(SET_NEW_OFFER, 'newOffer');

export const SET_OFFERS = 'SET_OFFERS';
export const setOffers = makeActionCreator(SET_OFFERS, 'offers');