import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFile';
import Dropzone from 'react-dropzone';

export class FileUploader extends Component {
  static propTypes = {
    acceptedFileTypes: PropTypes.string,
    handleNewFiles: PropTypes.func.isRequired,
  };

  static defaultProps = {
    acceptedFileTypes: 'image/jpeg, image/png, image/jpg',
  };

  render() {
    const { acceptedFileTypes, handleNewFiles } = this.props;

    return (
      <div className="file-uploader">
        <Dropzone
          onDrop={(acceptedFiles) => handleNewFiles(acceptedFiles)}
          accept={acceptedFileTypes}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className="dropzone-content"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <AttachFile />
              <Typography
                variant="h6"
                align="center"
              >
                {'Drag a File'}
              </Typography>
              <Typography
                variant="caption"
                align="center"
              >
                {'--OR--'}
              </Typography>
              <Button
                variant="contained"
                size="small"
              >
                {'Select one or more files from your computer'}
              </Button>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}
