import React from 'react';
import {app as firebaseApp} from 'service/firebase';
import { connect } from 'react-redux';
import { appSelectors, appActions } from 'app/redux';
import 'firebase/firestore';

export const LiveCounters_ = (props) => {

  const {account, location} = props;
  const [counters, setCounters] = React.useState(null);
  const [locationCounter, setLocationCounter] = React.useState(null);

  React.useEffect(() => {
    const db = firebaseApp.firestore();
    // TODO get account and location
    if(!account || !location) {
      return;
    }
    console.log(account, location);
    const accountDoc = db.collection('peoplecount').doc(account.shortCode);
    const locationDoc = accountDoc.collection('locations').doc(location.shortCode);

    const unsubscribe = {
      location: locationDoc.onSnapshot((doc) => setLocationCounter(doc.data())),
      counters: locationDoc.collection('counters').onSnapshot((snapshot) => 
        setCounters(snapshot.docs.map((doc) => doc.data())))
    };

    return () => {
      unsubscribe.location();
      unsubscribe.counters();
    };
  }, [account, location]);
  return (
    <React.Fragment>
      <h4>Live Counters</h4>
      { locationCounter && (
        <div>{`Main counter: ${locationCounter.count}/${locationCounter.maxCount}`}</div>
      )}
      { counters && counters.map(counter => (
        <div>{`${counter.name}: ${counter.count}/${counter.maxCount}`}</div>
      ))}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  account: appSelectors.account(state),
  location: appSelectors.location(state)
});


export const LiveCounters = connect(mapStateToProps, null)(LiveCounters_);