import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { appSelectors } from 'app/redux';
import * as NAVIGATION from 'config/navigation';
import { getAddressArray } from 'service/utility';


const CustomerDetails = (props) => {

  return (
    <div className="d-flex flex-column justify-content-start align-items-start">
      <span className="font-weight-thick text-small">
        {'Profile Information'}
      </span>
      <div className="border rounded w-100 p-3 d-flex flex-column justify-content-start align-items-start">
        {props.customer && props.customer.source && (
          <React.Fragment>
            <span>
              {props.customer}
            </span>
            <span>
              {'{{'}
            </span>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

CustomerDetails.propTypes = {
  customer: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  customer: appSelectors.customer(state),
});

export default connect(mapStateToProps)(CustomerDetails);
