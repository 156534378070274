import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { TextField, Portal } from '@material-ui/core';
import { isRequired } from 'service/utility/errorMessages';
import * as timeUtils from 'service/utility/timeUtils';

const DateFormatCustom = (props) => {
  const onValueChange = (values) => {
    props.onChange({
      target: {
        value: values.formattedValue,
      },
    });
  };

  const { inputRef, onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      decimalScale={0}
      type="tel"
      format="##/##/####"
      placeholder="MM/DD/YYYY"
      mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
    />
  );
};

DateFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const DatePickerWrapper = ({ children }) => (
  <Portal>
    <div>
      {children}
    </div>
  </Portal>
);

DatePickerWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]),
};

const DatePickerContainer = ({ className, children }) => (
  <div className="airbnb-datepicker-container">
    <div className="datepicker-container-inner">
      <div className={className}>
        <div className="position-relative">
          {children}
        </div>
      </div>
    </div>
  </div>
);

DatePickerContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]),
  className: PropTypes.string,
};

export class DateField extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    currentLocation: PropTypes.object,
    DatePickerProps: PropTypes.object,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    narrow: PropTypes.bool,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    useAirbnbStyle: PropTypes.bool,
    value: PropTypes.string,
  };

  static defaultProps = {
    autoFocus: false,
    DatePickerProps: {},
    error: '',
    fullWidth: false,
    label: '',
    narrow: false,
    required: false,
    useAirbnbStyle: false,
    value: '',
  };

  constructor(props) {
    super(props);

    const { value: stringValue, onChange } = this.props;
    const value = !stringValue || timeUtils.isEmptyIso8601Date(stringValue) ? null : stringValue;
    const error = this.validateSelf(value);

    this.state = {
      blurred: false,
    };

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value && required) return isRequired(label);

    return null;
  };

  handleLeaveFocus = () => {
    const { value: stringValue, onChange } = this.props;
    const value = stringValue || null;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (dateValue) => {
    const { onChange } = this.props;
    const value = dateValue ? timeUtils.dateYearMonthDay(dateValue) : null;
    const error = this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const {
      autoFocus, error, fullWidth, onChange, label, narrow, required, currentLocation, value: stringValue, DatePickerProps, useAirbnbStyle, ...rest
    } = this.props;

    const dateValue = !stringValue || timeUtils.isEmptyIso8601Date(stringValue) ? '' : timeUtils.localDateFromIso8601DateTime(stringValue);
    const { blurred } = this.state;

    return (
      <div className={`date-field${narrow ? ' narrow' : ''}`}>
        <DatePicker
          selected={dateValue}
          onChange={(date) => this.handleChangeSelf(date)}
          onBlur={() => this.handleLeaveFocus()}
          required={required}
          autoFocus={autoFocus}
          monthsShown={useAirbnbStyle ? 2 : 1}
          customInput={
            <TextField
              error={blurred && Boolean(error)}
              helperText={error}
              required={required}
              fullWidth={fullWidth}
              label={label}
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              variant="outlined"
              InputProps={{
                inputComponent: DateFormatCustom,
              }}
              {...rest}
            />
          }
          popperContainer={DatePickerWrapper}
          calendarContainer={useAirbnbStyle ? DatePickerContainer : null}
          {...DatePickerProps}
        />
      </div>
    );
  }
}
