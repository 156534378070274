import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { appActions, appSelectors } from 'app/redux';
import { getErrorMessage, useEffectAsync } from 'service/utility';

const StripeCallback_ = (props) => {
  useEffectAsync(async () => {
    const urlParams = new URLSearchParams(props.location.search);
    const retUrl = urlParams.get('retUrl');

    try {
      // nothing for now
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('adminAPI.fetchLocation error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    } finally {
      console.log(`StripeCallback: retUrl = ${retUrl}; redirecting...`);
      setTimeout(() => props.history.push(retUrl || '/'), 0);
    }
  }, []);

  return null;
};

StripeCallback_.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  account: appSelectors.account(state),
  location: appSelectors.location(state),
});

const mapDispatchToProps = (dispatch) => ({
});

export const StripeCallback = connect(mapStateToProps, mapDispatchToProps)(StripeCallback_);
