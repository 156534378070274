import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { leftPadZero } from 'service/utility/numbers';


export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;


export const isEmptyIso8601Date = (str) => str.substring(0, 10) === '0000-00-00';

export const iso8601Date = (m, timeZone) => moment(m).tz(timeZone).format('YYYY-MM-DD');

export const iso8601Today = (timeZone) => iso8601Date([], timeZone);

export const iso8601Hour = (m, timeZone) => moment(m).tz(timeZone).format('HH');

export const iso8601HourMinutes = (m, timeZone) => moment(m).tz(timeZone).format('HH:mm');

export const iso8601Time = (m, timeZone) => moment(m).tz(timeZone).format('HH:mm:ss');

export const iso8601DateTime = (m, timeZone) => moment(m).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss');

export const iso8601DTZ = (m, timeZone) => moment(m).tz(timeZone).format();

export const iso8601Now = (timeZone) => iso8601DTZ([], timeZone);

export const iso8601MidnightInUTC = (iso8601Date_, timeZone) => moment.tz(iso8601Date_, 'YYYY-MM-DD', timeZone).startOf('day').tz('UTC').format();

export const iso8601EodInUTC = (iso8601Date_, timeZone) => moment.tz(iso8601Date_, 'YYYY-MM-DD', timeZone).endOf('day').tz('UTC').format();

export const iso8601IsBeforeDate = (iso8601Date1, iso8601Date2) => moment(iso8601Date1, 'YYYY-MM-DD').isBefore(moment(iso8601Date2, 'YYYY-MM-DD'));

export const iso8601AddDays = (iso8601Date_, days) => moment(iso8601Date_, 'YYYY-MM-DD').add(days, 'd').format('YYYY-MM-DD');

export const iso8601DTZFromDate = (date, timeZone) => moment.tz(
  [date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()],
  timeZone
).format();

export const iso8601DateFromIso8601 = (iso8601Date_) => moment(iso8601Date_, 'YYYY-MM-DD').format('YYYY-MM-DD');

export const firebaseKeyDayStart = (iso8601Date_, timeZone) => moment.tz(iso8601Date_, 'YYYY-MM-DD', timeZone).utc().format('YYYY-MM-DDTHH:mm:ss');

export const firebaseKeyDayEnd = (iso8601Date_, timeZone) => moment.tz(iso8601Date_, 'YYYY-MM-DD', timeZone).endOf('d').utc().format('YYYY-MM-DDTHH:mm:ss');

export const firebaseKeyTodayStart = (timeZone) => firebaseKeyDayStart(iso8601Today(timeZone), timeZone);

export const firebaseKeyTodayEnd = (timeZone) => firebaseKeyDayEnd(iso8601Today(timeZone), timeZone);

export const numberOfDaysAgo = (iso8601Date_, timeZone) => (
  moment(iso8601Today(timeZone), 'YYYY-MM-DD').diff(moment(iso8601DateFromIso8601(iso8601Date_), 'YYYY-MM-DD'), 'd')
);

// age = the difference in years between a YYYY-MM-DD date and today
export const age = (iso8601Date_, timeZone) => moment(iso8601Today(timeZone), 'YYYY-MM-DD').diff(moment(iso8601Date_, 'YYYY-MM-DD'), 'y');

export const isInThePast = (m, timeZone) => moment.tz(m, timeZone).isBefore(moment.tz([], timeZone));

// By "isSameDate" it is meant "do these two moments fall on the same YYYY-MM-DD date for the specified timezone"
export const isSameDate = (m1, m2, timeZone) => moment(iso8601Date(m2, timeZone), 'YYYY-MM-DD').isSame(moment(iso8601Date(m1, timeZone), 'YYYY-MM-DD'), 'day');

// By "isSameMonth" it is meant "do these two moments fall on the same YYYY-MM month for the specified timezone"
export const isSameMonth = (m1, m2, timeZone) => moment(iso8601Date(m2, timeZone), 'YYYY-MM-DD').isSame(moment(iso8601Date(m1, timeZone), 'YYYY-MM-DD'), 'month');

// By "isSameAm" it is meant "are these two moments both in the AM (before noon) or in the PM (after noon) for the specified timezone"
export const isSameAm = (m1, m2, timeZone) => {
  const h1 = iso8601Hour(m1, timeZone);
  const h2 = iso8601Hour(m2, timeZone);

  return (h1 < 12 && h2 < 12) || (h1 >= 12 && h2 >= 12);
};

export const isToday = (m, timeZone) => moment(iso8601Today(timeZone), 'YYYY-MM-DD').isSame(moment(iso8601Date(m, timeZone), 'YYYY-MM-DD'), 'day');

export const relativeDateTime = (m, timeZone) => {
  const dt = moment(m).tz(timeZone);
  const diff = numberOfDaysAgo(m, timeZone);

  return `${(diff === 0 ? 'Today' : diff === 1 ? 'Yesterday' : `On ${dt.format('MMM Do')}`)} at ${dt.format('h:mm A')}`;
};

export const usDate = (m, timeZone) => moment(m).tz(timeZone).format('MM/DD/YYYY');

export const usDateToday = (timeZone) => usDate([], timeZone);

export const usDateFromIso8601 = (iso8601, timeZone) => moment.tz(iso8601, 'YYYY-MM-DD', timeZone).format('MM/DD/YYYY');

export const usTime = (m, timeZone) => moment(m).tz(timeZone).format('h:mm a');

export const usDateTime = (m, timeZone) => moment(m).tz(timeZone).format('MM/DD/YYYY h:mm a');

export const receiptDateTime = (m, timeZone) => moment(m).tz(timeZone).format('MMMM Do YYYY, h:mm a');

export const ticketDateTime = (m, timeZone) => moment(m).tz(timeZone).format('h:mm a, MM/DD/YYYY');

// builds a UTC Date() from a given local Date()
export const calendarRruleDate = (date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

// computes difference in hours and minutes between two Date()s
export const calendarEventDuration = (startDate, endDate) => {
  const diffDurationInMs = endDate.getTime() - startDate.getTime();
  const diffDurationInMinutes = diffDurationInMs / MINUTE;
  const diffMinutes = diffDurationInMinutes % 60;
  const diffHours = (diffDurationInMinutes - diffMinutes) / 60;

  return `${leftPadZero(diffHours, 2)}H${leftPadZero(diffMinutes, 2)}M`;
};

export const calendarPrevDayAtMidnight = (iso8601Date_, timeZone) => moment.tz(iso8601Date_, 'YYYY-MM-DD', timeZone).subtract(1, 'd').format('YYYY-MM-DDTHH:mm:ss');

// finds the next "quarter start" (:00, :15, :30, :45) after the current moment
export const calendarNextQuarterStart = () => {
  const now = Date.now();
  const mr = now % HOUR;
  const mr2 = mr > 45 * MINUTE ? 60 * MINUTE : mr > 30 * MINUTE ? 45 * MINUTE : mr > 15 * MINUTE ? 30 * MINUTE : mr > 0 ? 15 * MINUTE : 0;

  return new Date(now - mr + mr2);
};

// takes a Date() and adds 30 minutes
export const calendarHalfHourLater = (date) => new Date(date.getTime() + 30 * MINUTE);

// takes a Date() and returns the HH:MM part of it. No timezone, used for DatePickers that work with a Date()
export const dateHoursAndMinutes = (date) => `${leftPadZero(date.getHours(), 2)}:${leftPadZero(date.getMinutes(), 2)}`;

// takes a Date() and returns the YYYY-MM-DD part of it. No timezone, used for DatePickers that work with a Date()
export const dateYearMonthDay = (date) => `${date.getFullYear()}-${leftPadZero(date.getMonth() + 1, 2)}-${leftPadZero(date.getDate(), 2)}`;

export const datePickerDate = (date) => moment(date).format('MMM D, YYYY');

export const formatDateForEvents = (iso8601Date_) => moment(iso8601Date_, 'YYYY-MM-DD').format('dddd, MMMM D');

export const calEventDate = (date, timeZone) => moment(date).tz(timeZone).format('dddd, MMMM D');

export const calEventDateInterval = (date1, date2, timeZone) => `${moment(date1).tz(timeZone).format('MMMM D')} – ${moment(date2).tz(timeZone).format(`${isSameMonth(date1, date2, timeZone) ? '' : 'MMMM '}D, YYYY`)}`;

export const calEventTimeInterval = (date1, date2, timeZone) => `${moment(date1).tz(timeZone).format(`h:mm${isSameAm(date1, date2, timeZone) ? '' : 'a'}`)} – ${moment(date2).tz(timeZone).format('h:mma')}`;

export const calEventDateTimeInterval = (date1, date2, timeZone) => `${moment(date1).tz(timeZone).format('MMMM D, YYYY, h:mma')} – ${moment(date2).tz(timeZone).format('MMMM D, YYYY, h:mma')}`;

export const airbnbDateInterval = (iso8601Date1, iso8601Date2) => {
  if (!iso8601Date1 && !iso8601Date2) return 'Dates';

  const m1 = moment(iso8601Date1, 'YYYY-MM-DD');

  if (!iso8601Date2) return `${m1.format('MMM D')} - `;

  const m2 = moment(iso8601Date2, 'YYYY-MM-DD');
  const sameDay = m1.isSame(m2, 'day');

  if (sameDay) return `${m1.format('MMM D')}`;

  const sameMonth = m1.isSame(m2, 'month');

  if (sameMonth) return `${m1.format('MMM D')} - ${m2.format('D')}`;

  return `${m1.format('MMM D')} - ${m2.format('MMM D')}`;
};

// Takes a YYYY-MM-DD Date string and computes a Date() in the local timezone at midnight
export const localDateFromIso8601Date = (str) => new Date(...moment(str, 'YYYY-MM-DDT00:00:00').toArray());

// Takes a YYYY-MM-DDTHH:mm:ss DateTime string and computes a Date() in the local timezone
export const localDateFromIso8601DateTime = (str) => new Date(...moment(str, 'YYYY-MM-DDTHH:mm:ss').toArray());

export const getTimestamp = (m) => moment(m).valueOf();

export const getDurationSpan = (startDT, endDT, isAllDay, timeZone) => (
  <span>
    {isAllDay ? (
      isSameDate(startDT, endDT, timeZone) ? (
        calEventDate(startDT, timeZone)
      ) : (
        calEventDateInterval(startDT, endDT, timeZone)
      )
    ) : (
      isSameDate(startDT, endDT, timeZone) ? (
        <Fragment>
          {calEventDate(startDT, timeZone)}
          <span style={{ margin: '0 8px' }}>
            {'⋅'}
          </span>
          {calEventTimeInterval(startDT, endDT, timeZone)}
        </Fragment>
      ) : (
        calEventDateTimeInterval(startDT, endDT, timeZone)
      )
    )}
  </span>
);

export const getCalEventDuration = (calEvent, timeZone) => {
  if (!calEvent) return null;

  const { occurrence: { startDT, endDT }, time: { isAllDay } } = calEvent;

  return getDurationSpan(startDT, endDT, isAllDay, timeZone);
};

export const getBookingDuration = (booking, timeZone) => {
  if (!booking) return null;

  const { startDT, endDT, isAllDay } = booking;

  return getDurationSpan(startDT, endDT, isAllDay, timeZone);
};
