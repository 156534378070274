import React from 'react';
import { NumberField } from './NumberField';

export const CurrencyField = (props) => (
  <NumberField
    {...props}
    InputProps={{
      inputProps: {
        prefix: '$',
        decimalScale: 2,
        thousandSeparator: true,
      },
    }}
    className="currency-field"
  />
);
