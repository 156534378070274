import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, TextField, Paper, Typography } from '@material-ui/core';

import { authActions } from 'app/auth';
import { ButtonSpinner } from 'common/statusIndicators';
import * as NAVIGATION from 'config/navigation';
import * as authService from 'service/firebase';
import { authSelectors } from 'app/auth';


const Login_ = (props) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleInputChange = (setStateFunc) => (event) => {
    setLoginError(null);
    setStateFunc(event.target.value);
  };

  const handleLogIn = async () => {
    console.log('Attempting to sign to FireBase');
    setLoggingIn(true);

    const currentUser = await authService.getCurrentUser();

    const response = await authService.signIn(username, password);


    if (response.userCredential) {
      const user = response.userCredential.user;
      props.actions.signIn(user); // pushes user into store
      setLoggingIn(false);
      props.history.push(NAVIGATION.PROFILE);
    } else {
      console.log(`Error while signing to FireBase. Error code: ${response.error.code}. Error message: ${response.error.message}`);
      toast(response.error.message);
      setLoggingIn(false);
    }
  };

  // useEffect(() => {
  //   const AbortController = new AbortController();
  // })

  return (
    <div className="auth-screen">
      <Paper
        variant="outlined"
        classes={{ root: 'form-card' }}
      >
        <img
          className="header-logo"
          src={require('assets/pc-logo.svg')}
          alt="PeopleCount"
        />
        <form>
          <fieldset>
            <TextField
              label="Username"
              value={username}
              onChange={handleInputChange(setUsername)}
              variant="outlined"
              required
              fullWidth
              autoFocus
              className="mb-3"
            />
            <TextField
              type="password"
              label="Password"
              value={password}
              onChange={handleInputChange(setPassword)}
              error={Boolean(loginError)}
              helperText={loginError}
              variant="outlined"
              required
              fullWidth
              className="mb-3"
            />
          </fieldset>
          <div className="d-flex flex-row justify-content-end">
            <Button
              color="primary"
              variant="contained"
              onClick={handleLogIn}
              disabled={loggingIn}
            >
              {loggingIn ? 'Logging In...' : 'Log In'}
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

Login_.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    signIn: (user) => dispatch(authActions.signIn(user)),
  },
});

export const Login = connect(mapStateToProps, mapDispatchToProps)(Login_);
