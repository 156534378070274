import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { Avatar as Avatar2 } from '@material-ui/core';
import Photo from '@material-ui/icons/Photo';

export class Avatar extends Component {
  static propTypes = {
    alt: PropTypes.element,
    className: PropTypes.string,
    size: PropTypes.string,
    src: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    alt: <Photo />,
    className: '',
    size: 'md',
    src: '',
  }

  render() {
    const { alt, className, src, size, onClick } = this.props;

    const className2 = classnames(
      'avatar',
      {
        'avatar-xs': size === 'xs',
        'avatar-sm': size === 'sm',
        'avatar-lg': size === 'lg',
        'avatar-xl': size === 'xl',
        [className]: Boolean(className),
      }
    );

    return (
      <Avatar2
        classes={{ root: className2 }}
        src={src}
        children={!src && alt}
        onClick={onClick && onClick}
      />
    );
  }
}

