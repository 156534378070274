import styles from 'styles/_variables.scss';

const THEME_CONFIG = {
  palette: {
    primary: {
      main: styles.primary,
      contrastText: styles.contrastTextLight,
    },
    secondary: {
      main: styles.secondary,
    },
    text: {
      primary: styles.textPrimary,
      secondary: styles.textSecondary,
    },
  },
  overrides: {
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: "0.5rem"
        }
      }
    },
    MuiButton: {
      root: {
        padding: '3px 16px',
        borderRadius: 6,
      },
      contained: {
        boxShadow: 'none',
      },
      outlined: {
        padding: '3px 15px',
      },
    },
    MuiFormLabel: {
      root: {
        color: styles.labelColor,
      },
    },
    MuiFormHelperText: {
      root: {
        color: styles.helperTextColor
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: styles.inputBorderColor,
      },
      inputMarginDense: {
        paddingTop: '7px',
        paddingBottom: '7px',
      },
    },
    MuiListSubheader: {
      root: {
        color: styles.listHeaderColor,
        fontWeight: styles.listHeaderFontWeight,
        fontSize: styles.listHeaderFontSize,
      },
    },
    MuiListItemText: {
      primary: {
        color: styles.listItemPrimaryTextColor,
        fontSize: styles.listItemPrimaryTextSize,
      },
      secondary: {
        color: styles.listItemSecondaryTextColor,
        fontSize: styles.listItemSecondaryTextSize,
      },
      inset: {
        paddingLeft: 30,
      },
    },
    MuiLink: {
      root: {
        color: styles.linkColor,
      },
      underlineHover: {
        textDecoration: styles.linkDecoration,
      },
    },
    MuiCheckbox: {
      root: {
        color: styles.checkboxColor,
      },
      colorSecondary: {
        '&$checked': {
          color: styles.checkboxColor,
        },
      },
    },
  },
  typography: {
    fontFamily: styles.fontFamily,
    button: {
      fontWeight: styles.buttonFontWeight,
    },
  },
};

export default THEME_CONFIG;
