import { createReducer } from 'service/utility';

import { SET_ACCOUNT } from '../actions';
import { SIGN_OUT } from 'app/auth/redux/actions';

const initState = {};

export const account = createReducer(initState, {
  [SET_ACCOUNT]: (state, { account: account_ }) => account_,
  [SIGN_OUT]: () => null,
});
