import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { offerActions } from 'app';
import { PropTypes } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { appActions, appSelectors } from 'app/redux';

const useStyles = makeStyles((theme) => ({
  caption: {
    marginBottom: '1rem',
    marginLeft: '2rem',
    marginTop: '-1rem',
  },
  radioFormGroup: {
    marginLeft: '2rem',
    marginBottom: '1rem'
  },
  radioLabel: {
    marginTop: '1rem'
  }
}));

export const OfferType = (props) => {
  const classes = useStyles();
  const [type, setType] = React.useState('');
  const offerModel = props.offerModel; 
  const setOfferModel = props.setOfferModel; 

  useEffect(() => {
  }, []);

  const handleAdTypeChange = (event) => {
    setType(event.target.value);
    const type = event.target.value;
    setOfferModel({...offerModel,type: type});
  };

  return (
    <React.Fragment>
    <Typography variant="h6">Select Ad Type</Typography>
    <form className="offer-form">
      <FormControl component="fieldset" className={classes.radioFormGroup}>
        <RadioGroup aria-label="gender" name="gender1" value={offerModel.type} onChange={handleAdTypeChange}>
          <FormControlLabel value="fixed" className={classes.radioLabel}
          control={<Radio />} label="Fixed Ad" />
          <Typography variant="caption" className={classes.caption}>This ad will display based on times that you establish</Typography>
          <FormControlLabel value="capacity" className={classes.radioLabel}
          control={<Radio />} label="Capacity Based Ad" />
          <Typography variant="caption" className={classes.caption}>This ad will be released based on the capacity parameters that you establish</Typography>
        </RadioGroup>
      </FormControl>
    </form>
    </React.Fragment>
  )
}

OfferType.propTypes = {
  offerModel: PropTypes.object.isRequired
};