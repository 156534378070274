import { combineReducers } from 'redux';

import cart from './cart';
import loading from './loading';
import paymentStatus from './paymentStatus';


const cartReducer = combineReducers({
  cart,
  loading,
  paymentStatus,
});

export default cartReducer;
