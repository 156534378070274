import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { FormControl, FormLabel, FormGroup, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core';
import { Spinner } from 'common/statusIndicators';
import { isRequired } from 'service/utility/errorMessages';

export class RelatedCheckboxes extends Component {
  static propTypes = {
    error: PropTypes.string,
    getOptionsAPI: PropTypes.func.isRequired,
    getParams: PropTypes.any,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    value: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    error: '',
    required: false,
    value: [],
  };

  constructor(props) {
    super(props);

    const { value, onChange } = this.props;
    const error = this.validateSelf(value);

    this.state = {
      options: [],
      blurred: false,
      loading: true,
    };

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidMount = async () => {
    this._isMounted = true;
    const { getOptionsAPI, getParams } = this.props;

    const response = await getOptionsAPI(getParams);
    const options = response.data || response;

    if (this._isMounted) {
      this.setState({
        loading: false,
        options,
      });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  isChecked = (id) => this.props.value.indexOf(id) !== -1;

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value.length && required) return isRequired(label);

    return null;
  };

  handleChangeSelf = (id) => {
    const { value: oldValue, onChange } = this.props;
    const { blurred } = this.state;

    const idx = oldValue.indexOf(id);

    const value = idx === -1 ? oldValue.concat(id) : oldValue.filter((e, i) => i !== idx);
    const error = this.validateSelf(value);

    if (!blurred) this.setState({ blurred: true });

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const { label, error, required } = this.props;
    const { loading, options, blurred } = this.state;

    return !loading ? (
      <FormControl
        classes={{ root: 'related-checkboxes' }}
        component="fieldset"
        required={required}
        error={blurred && Boolean(error)}
      >
        <FormLabel component="legend">
          {label}
        </FormLabel>
        <FormGroup classes={{ root: 'form-group' }}>
          {options.map((item, idx) => (
            <FormControlLabel
              key={item.id || idx}
              control={
                <Checkbox
                  checked={this.isChecked(item.id)}
                  onChange={() => this.handleChangeSelf(item.id)}
                  value={item.id.toString()}
                />
              }
              label={item.name}
            />
          ))}
        </FormGroup>
        <FormHelperText>
          {error}
        </FormHelperText>
      </FormControl>
    ) : (
      <Spinner />
    );
  }
}
