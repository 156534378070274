import { CustomerSearch } from 'common/searches';
import { DATATYPES } from './DataTypes';

export const FIELD_GROUP_HEADER = 'FIELD_GROUP_HEADER';
export const FIELD_GROUP_DIVIDER = 'FIELD_GROUP_DIVIDER';

const Customer_ = {
  id: { name: 'id', type: DATATYPES.id },
  firstName: { name: 'firstName', type: DATATYPES.name, isRequired: true },
  lastName: { name: 'lastName', type: DATATYPES.name, isRequired: true },
  company: { name: 'company', type: DATATYPES.string },
  isMember: { name: 'isMember', type: DATATYPES.boolean },
  countryCode: { name: 'countryCode', type: DATATYPES.country },
  address1: { name: 'address1', type: DATATYPES.string },
  address2: { name: 'address2', type: DATATYPES.string },
  city: { name: 'city', type: DATATYPES.string },
  state: { name: 'state', type: DATATYPES.state },
  postalCode: { name: 'postalCode', type: DATATYPES.postalCode },
  billingAddress1: { name: 'billingAddress1', type: DATATYPES.string },
  billingAddress2: { name: 'billingAddress2', type: DATATYPES.string },
  billingCity: { name: 'billingCity', type: DATATYPES.string },
  billingState: { name: 'billingState', type: DATATYPES.string },
  billingPostalCode: { name: 'billingPostalCode', type: DATATYPES.string },
  email: { name: 'email', type: DATATYPES.email, isRequired: true },
  mobile: { name: 'mobile', type: DATATYPES.phone, isRequired: true },
  home: { name: 'home', type: DATATYPES.phone },
  metadata: { name: 'metadata', type: DATATYPES.string },
  parentCustomerId: { name: 'parentCustomerId', type: DATATYPES.id, searchComponent: CustomerSearch },
  createdAt: { name: 'createdAt', type: DATATYPES.datetime },
  updatedAt: { name: 'updatedAt', type: DATATYPES.datetime },
  addtionalInfo: { name: 'addtionalInfo', type: DATATYPES.string },
  notes: { name: 'notes', type: DATATYPES.string },
  isDependant: { name: 'isDependant', type: DATATYPES.boolean },
  climingStartDate: { name: 'climingStartDate', type: DATATYPES.datetime },
  UUID: { name: 'UUID', type: DATATYPES.string },
  birthdate: { name: 'birthdate', type: DATATYPES.date, isRequired: true },
  emergencyName: { name: 'emergencyName', type: DATATYPES.string, isRequired: true },
  emergencyNumber: { name: 'emergencyNumber', type: DATATYPES.phone, isRequired: true },
  emergencyRelation: { name: 'emergencyRelation', type: DATATYPES.string, isRequired: true },
  isCorporate: { name: 'isCorporate', type: DATATYPES.boolean },
};

Customer_.address = {
  type: DATATYPES.address,
  subFields: [
    Customer_.countryCode,
    Customer_.address1,
    Customer_.address2,
    Customer_.state,
    Customer_.city,
    Customer_.postalCode,
  ],
};

export const Customer = Customer_;


export const cartIdProp = 'UUID';