import { initAxios, initAxiosWidgets } from './axios';

export const getAccount = () => initAxios().then((axios) => axios.get('/account'));
export const getLocations = () => initAxios().then((axios) => axios.get('/loc'));
export const getAllOffers = (locationId) => initAxios().then((axios) => axios.get(`/loc/${locationId}/offers`));
export const getTodaysOffers = (locationId) => initAxios().then((axios) => axios.get(`/loc/${locationId}/offers/today`));
export const putOffer = (locationId,data) => initAxios().then((axios) => axios.put(`/loc/${locationId}/offers`, data));

export const getOffer = (accountId,locationId,offerId) => initAxios().then((axios) => axios.get(`/offer/${accountId}/${locationId}/${offerId}`));

export const createPaymentIntent = (data) => initAxios().then((axios) => axios.put(`/payment/intent/`,data));
export const confirmPaymentIntent = (data) => initAxios().then((axios) => axios.put(`/payment/confirm/`,data));

export const getAllOrders = (locationId) => initAxios().then((axios) => axios.get(`/loc/${locationId}/orders`));
export const putOrder = (locationId,data) => initAxios().then((axios) => axios.put(`/loc/${locationId}/orders`, data));
export const getOrderPublic = (accountId,locationId,orderId) => initAxios().then((axios) => axios.get(`/public/order/${accountId}/${locationId}/${orderId}`));
export const getOrder = (locationId,orderId) => initAxios().then((axios) => axios.get(`/loc/${locationId}/orders/${orderId}`));


export const getLocationById = (locationId) => initAxios().then((axios) => axios.get(`/locations/${locationId}`));
export const getMerchantPrivateKey = (accountId) => initAxios().then((axios) => axios.get(`/stripe/${accountId}/init`));
export const newSale = (orderId, data) => initAxios().then((axios) => axios.post(`/orders/${orderId}/sales`, data));
export const postOrderToCart = (cartId, data) => initAxios().then((axios) => axios.post(`/carts/${cartId}/orders`, data));
export const putPaymentCard = (data) => initAxios().then((axios) => axios.put('/customers/cards', data));

export const authorize = (accountId, next) => initAxios().then((axios) => axios.get(`/stripe/oauth/authorize?accountId=${accountId}&next=${next}`));
export const refreshToken = (accountId, refreshToken, next) => initAxios().then((axios) => axios.get(`/stripe/oauth/refreshtoken?accountId=${accountId}&refreshToken=${refreshToken}&next=${next}`));
