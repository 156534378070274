import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import AppConfig from 'config/app';
import { getNamespace } from 'service/axios';

export const app = firebase.initializeApp(AppConfig.firebase);

export const signIn = async (email, password) => {
  try {
    // const userCredential = firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    // .then(function() {
    //   return firebase.auth().signInWithEmailAndPassword(email, password);
    // })

    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);

    return { userCredential };
  } catch (e) {
    const error = {
      code: e.code,
      message: 'There was a problem signing in. Please try again.',
    };

    if (error.code === 'auth/invalid-email') {
      error.message = 'The email address is not valid.';
    } else if (error.code === 'auth/user-disabled') {
      error.message = 'The account with this email address has been disabled.';
    } else if (error.code === 'auth/user-not-found') {
      error.message = 'An account with this email does not exist. Please choose a different one.';
    } else if (error.code === 'auth/wrong-password') {
      error.message = 'Incorrect password. Please try again.';
    }

    console.log(`There was an error (code ${error.code}) : "${error.message}"`);

    return { error };
  }
};

export const signOut = async () => {
  try {
    await firebase.auth().signOut();

    return true;
  } catch (error) {
    console.log(`There was an error (code ${error.code}) : "${error.message}"`);

    return false;
  }
};

export const getIdToken = async () => {
  const currentUser = await firebase.auth().currentUser;
  const token = currentUser ? currentUser.getIdToken() : '';
  return token;
};

export const getCurrentUser = async () => {
  const token = await firebase.auth().currentUser;
  return token;
};


export const uploadFile = async (path, fileName, body) => {
  const fileRef = firebase.storage().ref().child(`namespace/${getNamespace()}/${path}/${fileName}`);
  const snapshot = await fileRef.putString(body, 'data_url');

  return snapshot.ref.getDownloadURL();
};

export const deleteFile = async (path, fileName) => {
  const fileRef = firebase.storage().ref().child(`namespace/${getNamespace()}/${path}/${fileName}`);

  await fileRef.delete();
};

export const uploadImage = async (path, body) => await uploadFile(path, 'avatar', body);
