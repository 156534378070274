export const HOME = '/';
export const LOGIN = '/login';
export const PROFILE = '/profile';
export const TRANSACTION_LIST = '/transactions';
export const LOCATION_LIST = '/locations';
export const OFFER_LIST = '/offers';
export const OFFER_NEW = '/offers/new';
export const OFFER_EDIT = '/offers/edit';
export const TODAYS_OFFERS = '/todays-offers';
export const ORDER_HISTORY = '/order-history';
export const ORDERS = '/orders';
export const LIVE_COUNTERS = '/live-counters';
export const COUNTER_DATA = '/counters';
export const TRAFFIC = '/traffic';
export const AUTHORIZATIONS = '/authorizations';
export const STRIPE_CALLBACK = '/stripe-callback';
export const CHECKOUT = '/checkout';
export const CART = '/cart';
export const ORDER_CONFIRMATION = '/order-confirmation';
