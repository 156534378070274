import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { PropTypes } from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PhoneIcon from '@material-ui/icons/Phone';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useLocation } from 'react-router-dom'

import { appSelectors } from 'app/redux';
import { appActions } from 'app/redux';
import * as NAVIGATION from 'config/navigation';

const drawerWidth = 240;
const primaryColor = '#172c5f';
const iconColor = '#00d3bb';
const backgroundColor = '#55ddcf';
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // backgroundColor: ''
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    // backgroundColor: backgroundColor
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    // color: iconColor
  },
  toolbarItem: {
    // color: iconColor
  }
}));

const SideBar_ = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { sidebar: { isOpen } } = props;

  const {pathname : location} = useLocation();

  const handleDrawerClose = () => {
    props.actions.setSidebarCollapsed();
  };

  return (
    <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
      >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <Typography primary="Staff screens" className="h6" />
      <List>
        {/* <ListItem selected={location === `/${NAVIGATION.TODAYS_OFFERS}`} component={Link} to={NAVIGATION.TODAYS_OFFERS}>
          <ListItemIcon className={classes.toolbarIcon}><TodayOutlinedIcon /></ListItemIcon>
          <ListItemText className={classes.toolbarItem} primary="Today's Offers" disableTypography={true} />
        </ListItem> */}
        <ListItem selected={location === `/${NAVIGATION.PROFILE}`} component={Link} to={NAVIGATION.PROFILE}>
          <ListItemIcon className={classes.toolbarIcon}><PhoneIcon /></ListItemIcon>
          <ListItemText className={classes.toolbarItem} primary="Contact" disableTypography={true} />
        </ListItem>
        <ListItem selected={location === `/${NAVIGATION.ORDER_HISTORY}`} component={Link} to={NAVIGATION.ORDER_HISTORY}>
          <ListItemIcon className={classes.toolbarIcon}><AttachMoneyIcon /></ListItemIcon>
          <ListItemText className={classes.toolbarItem} primary="Orders" disableTypography={true} />
        </ListItem>
        <ListItem selected={location === `/${NAVIGATION.LIVE_COUNTERS}`} component={Link} to={NAVIGATION.LIVE_COUNTERS}>
          <ListItemIcon className={classes.toolbarIcon}><PersonAddOutlinedIcon /></ListItemIcon>
          <ListItemText className={classes.toolbarItem} primary="Live Counters" disableTypography={true} />
        </ListItem>
      </List>
      <Divider />
      <Typography primary="Admin screens" className="h6" />
      <List>
        <ListItem  selected={location === `/${NAVIGATION.OFFER_LIST}`} component={Link} to={NAVIGATION.OFFER_LIST}>
          <ListItemIcon className={classes.toolbarIcon}><BookmarksOutlinedIcon /></ListItemIcon>
          <ListItemText className={classes.toolbarItem} primary="Offers" disableTypography={true} />
        </ListItem>
        {/* <ListItem disabled={true} selected={location === `/${NAVIGATION.COUNTER_DATA}`} component={Link} to={NAVIGATION.COUNTER_DATA}>
          <ListItemIcon className={classes.toolbarIcon}><TransferWithinAStationIcon /></ListItemIcon>
          <ListItemText className={classes.toolbarItem} primary="Counter Data" disableTypography={true} />
        </ListItem>
        <ListItem disabled={true} selected={location === `/${NAVIGATION.TRAFFIC}`} component={Link} to={NAVIGATION.TRAFFIC}>
          <ListItemIcon className={classes.toolbarIcon}><ShowChartIcon /></ListItemIcon>
          <ListItemText className={classes.toolbarItem} primary="Traffic" disableTypography={true} />
        </ListItem> */}
        
        {/* <ListItem disabled={true} selected={location === `/${NAVIGATION.AUTHORIZATIONS}`} component={Link} to={NAVIGATION.AUTHORIZATIONS}>
          <ListItemIcon className={classes.toolbarIcon}><StarBorderIcon /></ListItemIcon>
          <ListItemText className={classes.toolbarItem} primary="Staff" disableTypography={true} />
        </ListItem> */}
      </List>
    </Drawer>
  );
};
SideBar_.propTypes = {
  actions: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  sidebar: appSelectors.sidebar(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setSidebarCollapsed: () => dispatch(appActions.setSidebarCollapsed())
  }
});
export const SideBar = connect(mapStateToProps, mapDispatchToProps)(SideBar_);