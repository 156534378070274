import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { getOrder, getOffer } from 'service/api';
import { connect } from 'react-redux';
import { appSelectors } from 'app/redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';

const lightText = 'rgba(6,52,94,0.9)';
const regularText = 'rgba(6,52,94,1)';
const blackText = '#555555';

const useStyles = makeStyles((theme) => ({
  soil: {
    display: 'flex',
  },
  root: {
    flexGrow: 1,
    padding: '2rem',
    margin: 'auto',
  },
  title: {
    textAlign: 'center'
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: '1.1em',
    paddingTop: '0.3em'
  },
  detailTitle: {
    color: lightText,
    fontWeight: 300,
    fontSize: '0.9em',
    display: 'block'
  },
  detailContent: {
    color: regularText,
    fontWeight: 400,
    fontSize: '1em'
  },
  terms: {
    fontWeight: 300,
    fontSize: '1.2em',
    color: blackText
  },
  borderRight: {
    // borderRight: '1px solid rgba(6,52,94,0.7)'
  },
  footPrint: {
    color: blackText,
    fontSize: '0.9em',
    textAlign: 'center'
  }
}));

const OrderDetail = (props) => {
  const classes = useStyles();
  const [order,setOrder] = React.useState(null);
  const [offer,setOffer] = React.useState(null);
  const [orderStatus,setOrderStatus] = React.useState(null);
  const [orderAmount,setOrderAmount] = React.useState(null);
  const [orderCheckinDate,setOrderCheckinDate] = React.useState(null);
  const [orderPurchaseDate,setOrderPurchaseDate] = React.useState(null);
  const [stripeInfo,setStripeInfo] = React.useState(null);
  const orderId = props.match.params.orderDetailId;
  React.useEffect(() => {
    const fetchOrder = async () => {
      try {
        const { data } = await getOrder(props.location.id,orderId);
        if (data) {
          console.log(data);
          const stres = JSON.parse(data.stripeResponse);
          const orderStatus = data.status ? data.status.toUpperCase() : 'N/A';
          const orderAmount = (parseInt(data.orderTotal) / 100);
          const orderPurchaseDate = moment(data.purchaseDate).format('MM/DD/YYYY h:mm a');
          const orderCheckinDate = data.checkinDate ? moment(data.checkinDate).format('MM/DD/YYYY h:mm a') : '';
          console.log(stres);
          setStripeInfo(stres);
          setOrder(data);
          setOrderAmount(orderAmount);
          setOrderCheckinDate(orderCheckinDate);
          setOrderPurchaseDate(orderPurchaseDate);
          setOrderStatus(orderStatus);
          const { data: {offer} } = await getOffer(data.accountId,props.location.id, data.offerId);
          console.log(offer);
          if (offer) {
            setOffer(offer);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    orderId && fetchOrder();
  },[orderId]);

  return (
    
    <div className={classes.soil}>
      <div className={classes.root}>
    { (order && offer && stripeInfo) && (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}><div className={classes.sectionTitle}>Order</div></Grid>
          <Grid item xs={12} sm={3}>
            <Grid item className={classes.detailTitle}>Order number</Grid>
            <Grid item className={classes.detailContent}>{`#${order.id}`}</Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid item className={classes.detailTitle}>Order status</Grid>
            <Grid item className={classes.detailContent}>{orderStatus}</Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid item className={classes.detailTitle}>Purchased at</Grid>
            <Grid item className={classes.detailContent}>{orderPurchaseDate}</Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid item className={classes.detailTitle}>Checked in at</Grid>
            <Grid item className={classes.detailContent}>{orderCheckinDate}</Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid item className={classes.detailTitle}>Payment method</Grid>
            <Grid item className={classes.detailContent}>{order.paymentMethod}</Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid item className={classes.detailTitle}>Offer</Grid>
            <Grid item className={classes.detailContent}>{offer.name}</Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}><div className={classes.sectionTitle}>Customer</div></Grid>
          <Grid item xs={12} sm={4}>
            <Grid item className={classes.detailTitle}>Name</Grid>
            <Grid item className={classes.detailContent}>{order.customerName}</Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid item className={classes.detailTitle}>Email</Grid>
            <Grid item className={classes.detailContent}>{order.customerEmail}</Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid item className={classes.detailTitle}>Phone</Grid>
            <Grid item className={classes.detailContent}>{order.customerPhone}</Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}><div className={classes.sectionTitle}>Stripe response</div></Grid>
          <Grid item xs={12} sm={4}>
            <Grid item className={classes.detailTitle}>Payment ID</Grid>
            <Grid item className={classes.detailContent}>{stripeInfo.id}</Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid item className={classes.detailTitle}>Payment status</Grid>
            <Grid item className={classes.detailContent}>{stripeInfo.status}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    )}
    </div>
    </div>
  );
}

OrderDetail.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  location: appSelectors.location(state),
});

export default connect(mapStateToProps)(OrderDetail);