import React from 'react';
import { connect } from 'react-redux';
import { appSelectors, appActions } from 'app/redux';
import { putOffer } from 'service/api';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepConnector from '@material-ui/core/StepConnector';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { DateField } from 'common/formFields';
import TextField from '@material-ui/core/TextField';
import { getErrorMessage } from 'service/utility';
import * as NAVIGATION from 'config/navigation';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';

import { OfferTitle, OfferType, FixedAdParams, CapAdParams, Terms, UploadOffer, Review } from './steps';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#03aaf4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#03aaf4',
    },
  },
  line: {
    borderColor: '#aaaaaa',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#aaaaaa',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#03aaf4',
  },
  pending: {
    color: 'red'
  },
  completed: {
    color: '#03aaf4',
    zIndex: 1,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed, pending } = props;



  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active, [classes.pending]: pending
      })}
    >
      {completed ? <CheckCircleIcon className={classes.completed} /> : 
        <RadioButtonUncheckedIcon className={classes.circle} />}
    </div>
  );
  // TODO mark step as incomplete
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  pending: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBottom: '2rem'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  caption: {
    marginBottom: '1rem',
    marginLeft: '2rem',
    marginTop: '-1rem',
  },
  radioFormGroup: {
    marginLeft: '2rem',
    marginBottom: '1rem'
  },
  radioLabel: {
    marginTop: '1rem'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
}));

const getSteps = () => {
  return ['Offer Title','Offer Type','Parameters','Terms and Conditions','Upload Offer','Review and Finalize'];
}

const now = DateTime.local();
const ianaTimeZone = now.toFormat('z');

const NEW_OFFER_MODEL = {type:"",name:"",
  description:"",employeeNotes:"",product:"",
  minOccupancy:0, timeBelowOccupancy:0, startDate:"", endDate:"", 
  startTime:"",endTime:"",tax:0,
  totalAvailable:0,totalUsed:0, fullPrice:0, percentOff:0, salePrice:0, 
  isRepeatable:false,timesEnabled:"",daysEnabled:"",imageURL:"",terms:"",timezone:ianaTimeZone
};

export const NewOffer_ = (props) => {
  const classes = useStyles();
  const { match } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  // const [newOffer,OfferModel] = React.useState(NEW_OFFER_MODEL);
  const offerModel = {...NEW_OFFER_MODEL,...props.newOffer};;
  const steps = getSteps();

  const setOfferModel = (newOff) => {
    props.setNewOffer(newOff);
  }

  const isOfferValid = () => {
    return false;
  };

  // TODO change this so that the child components read from the state

  const offerTitleSection = OfferTitle({offerModel,setOfferModel});
  const offerTypeSection = OfferType({offerModel,setOfferModel});
  const fixedAdParamsSection = FixedAdParams({offerModel,setOfferModel});
  const capAdParamsSection = CapAdParams({offerModel,setOfferModel});
  const termsSection = Terms({offerModel,setOfferModel});
  const uploadOfferSection = UploadOffer({offerModel,setOfferModel});
  const reviewSection = Review({offerModel});

  const handleNext = () => {
    if(activeStep === (steps.length - 1)) {
      // save
      handleSaveOffer();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGoTo = (index) => {
    setActiveStep(index);
  }

  const handleSaveOffer = async () => {
    if(!props.location.id) { alert('no location selected'); return;}
    const locationId = props.location.id;
    try {
      const _offerModel = JSON.parse(JSON.stringify(offerModel));
      _offerModel["startDate"] = moment(offerModel.startDate).format('YYYY-MM-DD'); 
      _offerModel["endDate"] = moment(offerModel.endDate).format('YYYY-MM-DD'); 
      _offerModel["startTime"] = moment(offerModel.startTime).format('H:mm'); 
      _offerModel["endTime"] = moment(offerModel.endTime).format('H:mm'); 
      console.log(_offerModel);
      const { data } = await putOffer(locationId,_offerModel);
      if(data) {
        setOfferModel(null);
        props.history.push(NAVIGATION.OFFER_LIST);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error(errorMessage);
      console.error(error);
    } 
  }
  
  const getStepContent = (stepIndex) => ({
    "0": offerTitleSection,
    "1": offerTypeSection,
    "2": offerModel.type === 'fixed' ? fixedAdParamsSection : capAdParamsSection,
    "3": termsSection,
    "4": uploadOfferSection,
    "5": reviewSection,
  })[stepIndex];


  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div className={classes.content}>{getStepContent(activeStep)}</div>
        <div className={classes.buttonGroup}>
          <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton} >
            Back
          </Button>
          <Button color="primary" onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Save Offer' : 'Next'}
          </Button>
          {/* {activeStep === steps.length - 1 && ( <Button disabled={!isOfferValid()}>Save and Publish</Button>)} */}
        </div>
      </div>
    </div>
  );
};

NewOffer_.propTypes = {
  newOffer: PropTypes.object,
  location: PropTypes.object,
  setNewOffer: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  newOffer: appSelectors.newOffer(state),
  location: appSelectors.location(state)
});

const mapDispatchToProps = (dispatch) => ({
  setNewOffer: (offerModel) => dispatch(appActions.setNewOffer(offerModel)),
});

export const NewOffer = connect(mapStateToProps, mapDispatchToProps)(NewOffer_);