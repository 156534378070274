import { authActions } from 'app/auth';
import { createReducer } from 'service/utility';

import { SET_PAYMENT_STATUS, RESET_PAYMENT_STATUS } from '../actions';

const { SIGN_OUT } = authActions;


const initialState = {
  message: null,
  started: false,
  success: false,
  finished: false,
  actions: {},
};

const paymentStatus_ = createReducer(initialState, {
  [SET_PAYMENT_STATUS]: (state, { payload }) => ({ ...state, ...payload }),
  [RESET_PAYMENT_STATUS]: () => initialState,
  [SIGN_OUT]: () => initialState,
});

export default paymentStatus_;
