import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles({
  labelRoot: {
    position: 'relative',
  },
  labelShrink: {
    transform: 'none !important'
  },
  inputRoot: {
    marginLeft: '3rem',
    border: '1px solid',
    borderRadius: '5px'
  },
  inputInput: {
    padding: '5px 15px'
  },
  helperTextRoot: {
    marginLeft: '3.2rem'
  },
  root: {
    marginBottom: '1rem'
  }
});

export const SelectableInput = (props) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} justify="flex-start">
      <Grid>
        <Checkbox />
      </Grid>
      <Grid item xs>
        <TextField {...props} variant="outlined" size="small" />
      </Grid>
    </Grid>
  );
}
