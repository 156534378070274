import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Tabs, Tab, Button } from '@material-ui/core';
import { FileUploader, WebcamDialog } from 'common/images';

export class ImageDialogView extends Component {
  static propTypes = {
    cancelSelf: PropTypes.func.isRequired,
    documentsOnly: PropTypes.bool,
    handleNewImageFromWebcam: PropTypes.func.isRequired,
    handleNewUploadedFiles: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleTabChange: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    mode: PropTypes.string,
    resetState: PropTypes.func.isRequired,
    title: PropTypes.string,
    uploaded64: PropTypes.any,
  };

  static defaultProps = {
    title: 'Add Image',
  };

  render() {
    const {
      isOpen, cancelSelf, resetState, mode, title, documentsOnly, uploaded64,
      handleTabChange, handleSave, handleNewUploadedFiles, handleNewImageFromWebcam,
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={cancelSelf}
        onExited={resetState}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        {!documentsOnly && (
          <Tabs
            value={mode}
            onChange={handleTabChange}
          >
            <Tab
              value="upload"
              label="Upload"
            />
            <Tab
              value="webcam"
              label="Take Photo"
            />
          </Tabs>
        )}
        {mode === 'upload' && uploaded64 && (
          <div className="upload-preview-wrap">
            <img
              className="upload-preview"
              src={uploaded64}
              alt="preview"
            />
          </div>
        )}
        {mode === 'upload' && !uploaded64 && (
          <FileUploader handleNewFiles={handleNewUploadedFiles} />
        )}
        {mode === 'webcam' && (
          <DialogContent>
            <WebcamDialog handleNewImage={handleNewImageFromWebcam} />
          </DialogContent>
        )}
        <DialogActions>
          {uploaded64 && (
            <Button onClick={handleSave}>
              {'Save'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}
