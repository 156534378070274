import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Person from '@material-ui/icons/Person';
import { Avatar } from './Avatar';

export class PersonAvatar extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.string,
    src: PropTypes.string,
  };

  static defaultProps = {
    size: 'md',
    src: '',
  }

  render() {
    const { src, size, onClick } = this.props;

    return (
      <Avatar
        className="person-avatar"
        size={size}
        src={src}
        alt={<Person />}
        onClick={onClick}
      />
    );
  }
}

