import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@material-ui/core';

export class FilterButton extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.node,
      PropTypes.array,
    ]),
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Button
        color="secondary"
        variant="contained"
        classes={{ root: 'filter-button' }}
        {...rest}
      >
        {children}
      </Button>
    );
  }
}
