import { makeActionCreator } from 'service/utility';

export const SIGN_IN = 'SIGN_IN';
export const signIn = makeActionCreator(SIGN_IN, 'user');

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const setCurrentUser = makeActionCreator(SET_CURRENT_USER, 'user');

export const SIGN_OUT = 'SIGN_OUT';
export const signOut = makeActionCreator(SIGN_OUT);
