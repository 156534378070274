import React from 'react';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'common/formFields';
import Grid from '@material-ui/core/Grid';
import { ImageControl } from 'common/images';
import { FileUploader } from 'common/images';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { uploadImage } from 'service/firebase';

const useStyles = makeStyles((theme) => ({
  imgBackdrop: {
    padding: '1rem',
    background: 'rgba(0,0,0,0.1)',
    minHeight: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imgElement: {
    width: '400px'
  }
}));

export const UploadOffer = ({offerModel,setOfferModel}) => {
  const [uploadedFile, setUploadedFile] = React.useState(); 
  const [uploaded64, setUploaded64] = React.useState(offerModel.imageURL); 
  const classes = useStyles();

  React.useEffect(() => {
    if(offerModel && offerModel.imageURL) setUploaded64(offerModel.imageURL);
  },[offerModel])

  const handleNewUploadedFiles = (newFiles) => {
    const file = newFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const fileAsBase64 = reader.result;

      setUploadedFile(file);
      setUploaded64(fileAsBase64);
      handleUploadImage(fileAsBase64);
    };

    reader.readAsDataURL(file);
  }

  const handleUploadImage = async (base64) => {
    const randomId = parseInt(Math.random() * 100000); // not sure about this
    const imageURL = await uploadImage(`offer/${randomId}`, base64);
    offerModel = {...offerModel, imageURL: imageURL};
    setOfferModel(offerModel);
  } 
  React.useEffect(() => {
    const imgElement = document.getElementById("offerImage");
    imgElement && console.log(imgElement.naturalHeight);
  });

  return (
    <React.Fragment>
    <Typography variant="h6">Upload Offer</Typography>
    <form className="offer-form">
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography>Offer Image</Typography>
          <FileUploader handleNewFiles={handleNewUploadedFiles} />
          <Typography variant="body2" gutterBottom>You can upload an image created with your favorite editor</Typography>
          <Typography variant="body2">We recommend a square image no smaller than 400 pixels wide</Typography>
        </Grid>
        <Grid item xs>
          <div className={classes.imgBackdrop}>
            <img id="offerImage" src={uploaded64} className={classes.imgElement}/>
          </div>
        </Grid>
      </Grid>
    </form>
    </React.Fragment>
  )
}