import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { CircularProgress } from '@material-ui/core';

export class Spinner extends Component {
  static propTypes = {
    size: PropTypes.number,
  };

  static defaultProps = {
    size: 40,
  };

  render() {
    const { size } = this.props;

    return (
      <div className="spinner-container">
        <CircularProgress size={size} />
      </div>
    );
  }
}
