import { combineReducers } from 'redux';

import { account } from './account';
import { location, locations } from './location';
import { newOffer, offers } from './offer';
import { sidebar } from './sidebar';

export const appReducer = combineReducers({
  account,
  location,
  locations,
  newOffer,
  offers
});
export const layoutReducer = combineReducers({
  sidebar
});
