import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, Fab, Input, Link as ButtonLink, Checkbox } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';

import { appSelectors } from 'app/redux';
import PassIcon from '@material-ui/icons/LocalOfferOutlined';
import { TermsAndConditionsDialog } from 'common/dialogs';


const CartOffer = (props) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const displaySalePrice = (props.item.salePrice / 100);

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleAgreeTerms = () => {
    props.onTermsAndConditionsAgreed(true);
    setShowDialog(false);
  };

  const handleDisagreeTerms = () => {
    props.onTermsAndConditionsAgreed(false);
    setShowDialog(false);
  };


  return (
    <React.Fragment>
      <div className="cart-item border-top">
        <div className="cart-item-col image">
          <div className="mp-icon-wrapper">
            <PassIcon />
          </div>
        </div>
        <div className="cart-item-col details">
          <span className="product-name">
            {props.item.product}
          </span>
        </div>
        <br className="breaker" />
        <div className="cart-item-col pricing">
          <span className="price">
            {`$${displaySalePrice}`}
          </span>
        </div>
      </div>
      {props.onTermsAndConditionsAgreed && (
        <ButtonLink
          component="button"
          onClick={handleOpenDialog}
          className="text-primary d-flex align-items-center pt-1"
        >
          <span>
            {'Accept terms and conditions'}
          </span>
          <Checkbox
            checked={Boolean(props.termsAgreed)}
            classes={{ root: 'text-quinary py-0' }}
          />
        </ButtonLink>
      )}
      {showDialog && (
        <TermsAndConditionsDialog
          termsAndConditions={props.item.terms}
          onAgree={handleAgreeTerms}
          onDisagree={handleDisagreeTerms}
        />
      )}
    </React.Fragment>
  );
};

CartOffer.propTypes = {
  item: PropTypes.object.isRequired,
  onTermsAndConditionsAgreed: PropTypes.func,
};


const mapStateToProps = (state) => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CartOffer);
