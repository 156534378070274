import { createReducer } from 'service/utility';

import { SET_SIDEBAR_COLLAPSED, SET_SIDEBAR_EXPANDED } from '../actions';

const initState = {isOpen: true};

export const sidebar = createReducer(initState, {
  [SET_SIDEBAR_EXPANDED](state, { isOpen }) {
    return {
      ...state,
      isOpen: true,
    };
  },
  [SET_SIDEBAR_COLLAPSED](state, { isOpen }) {
    return {
      ...state,
      isOpen: false,
    };
  },
});
