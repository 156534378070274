import React, { Component } from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';

export class ButtonSuccess extends Component {
  render() {
    return (
      <div className="button-success-container">
        <CheckCircle fontSize="inherit" />
      </div>
    );
  }
}
