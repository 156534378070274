import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { appReducer, layoutReducer } from 'app/redux';
import { authReducer } from 'app/auth';
import { cartReducer } from 'app/cart';
import { loadState } from 'service/storage';
import { initSagas } from './initSagas';

const getReducer = (history) => combineReducers({
  app: appReducer,
  layout: layoutReducer,
  auth: authReducer,
  cart: cartReducer,
  router: connectRouter(history),
});

export const getStore = (history) => {

  const sagaMiddleware = createSagaMiddleware();
  const middleWares = [routerMiddleware(history), sagaMiddleware];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const composables = [applyMiddleware(...middleWares)];
  const enhancer = composeEnhancers(...composables);
  const reducer = getReducer(history);
  const initialState = loadState();
  const store = createStore(
    reducer,
    initialState,
    enhancer
  );

  initSagas(sagaMiddleware);

  return store;
};
