import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ImageCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

export class ImageCropper extends Component {
  static propTypes = {
    handleCroppedImage: PropTypes.func,
    imgSrc: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      crop: {
        x: 0,
        y: 0,
        maxHeight: 300,
        aspect: 1,
      },
    };
  }

  onImageLoaded = (image) => {
    this.setState({
      crop: {
        x: 0,
        y: 0,
        width: image.width,
        height: image.height,
      },
      image,
    });

    return false;
  };

  onCropComplete = (crop) => {
    const { handleCroppedImage } = this.props;

    if (handleCroppedImage) {
      const croppedImage = this.getCroppedImage(crop);

      handleCroppedImage(croppedImage);
    }
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  getCroppedImage = (crop) => {
    const { image } = this.state;

    // const image = new Image();
    // image.src = this.props.imgSrc;

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    // As Base64 string
    const base64Image = canvas.toDataURL('image/jpeg');

    return base64Image;

    // As a blob
    // Promise so call needs to prepend await (await this.getCroppedImage() )
    // return new Promise((resolve, reject) => {
    //   canvas.toBlob(file => {
    //     file.name = fileName;
    //     resolve(file);
    //   }, 'image/jpeg');
    // });
    //
  };

  render() {
    const { imgSrc } = this.props;
    const { crop } = this.state;

    return (
      <ImageCrop
        crop={crop}
        src={imgSrc}
        onImageLoaded={this.onImageLoaded}
        onChange={this.onCropChange}
        onComplete={this.onCropComplete}
      />
    );
  }
}
