import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InputLabel, Button } from '@material-ui/core';
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { cartActions, cartSelectors } from 'app/cart';
import { appSelectors } from 'app/redux';
import { TransactionStatus } from 'common/cart';
import { Spinner } from 'common/statusIndicators';
import APP_CONFIG from 'config/app';
import * as NAVIGATION from 'config/navigation';
import * as API from 'service/api';
import { useEffectAsync, getErrorMessage, loadDynamicScript } from 'service/utility';


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};


const CreditCardForm = (props) => {
  const history = useHistory();

  const [merchant, setMerchant] = React.useState(null);
  const [privatekey, setPrivatekey] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [publicKey, setPublicKey] = React.useState(null);
  const [clientId, setClientId] = React.useState(null);
  // const [clientSecret, setClientSecret] = React.useState(null);
  const [stripePromise, setStripePromise] = React.useState(null);
  const [hosted, setHosted] = React.useState(null);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [savingCard, setSavingCard] = React.useState(false);
  const [merchantLoading, setMerchantLoading] = React.useState(false);
  const [merchantLoaded, setMerchantLoaded] = React.useState(false);
  const [merchantScriptLoading, setMerchantScriptLoading] = React.useState(false);
  const [merchantScriptLoaded, setMerchantScriptLoaded] = React.useState(false);
  const [merchantScriptFailedLoading, setMerchantScriptFailedLoading] = React.useState(false);
  const [orderTotal,setOrderTotal] = React.useState(null);
  const [paymentIntent,setPaymentIntent] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState(false);
  const [transactionInProgress, setTransactionInProgress] = React.useState(false);


  const createPaymentIntent = async() => {
    setMerchantLoading(true);
    setLoadingMessage('Loading payment resources');
    const amount = props.cart.cart.total;
    // send more info so that the order can be created
    const accountId = props.cart.cart.offer.accountId;
    const {cart: { cart: { customer: { name, email}}}} = props;
    
    const result = await API.createPaymentIntent({amount, accountId, name, email});

    setPaymentIntent(result.data);
      
    if (result.error) {
      console.error(result.error);
      // do something if it fails
      toast.error('Could not setup the transaction, please try again.');
    } 
    setMerchantLoading(false);
    setLoadingMessage(null);
    // save payment intent key
  }

  const checkout = async (elements, stripe) => {
    const amount = props.cart.cart.total;
    const customer = props.cart.cart.customer;

    const ps = { message: 'Creating payment', started: true, success: false, finished: false, actions: {}, };
    props.setPaymentStatus(ps);
    setTransactionInProgress(true);

    console.log('stripe in use:', stripe);

    
    try {
      const cardResult = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      },{stripeAccount: clientId});
      console.log(cardResult);
      const cardToUse = cardResult.paymentMethod;
      const offerId = props.cart.cart.offer.id;
      const accountId = props.cart.cart.offer.accountId;
      const locationId = props.cart.cart.locationId;
      const orderTotal = props.cart.cart.total;
      const data = {
        paymentIntent,cardToUse,customer,offerId,locationId,accountId,orderTotal
      }
  
      console.log(paymentIntent);
  
      props.setPaymentStatus({...ps,...{message:'Confirming Payment',started:true,success: false, finished:false}});

      const result = await API.confirmPaymentIntent(data);
      console.log(result);
      if (result.error) {
        setTransactionInProgress(false);
  
        console.log('stripe transaction failed');
        console.log(result.error.message);
        setSavingCard(false);
        props.setPaymentStatus({...ps,...{message:'Payment failure',success: false, finished:true}})
      } else {
        setTransactionInProgress(false);

        const { data: { savedOrder }} = result;

        const ids = {accountId,locationId,offerId,orderId:savedOrder.id};
  
        props.setPaymentStatus({...ps,...{message:'Payment success',success: true, finished:true, actions: ids}})
      }
    } catch (e) {
      const { response : { data : { message }}} = e;
      setTransactionInProgress(false);
      setSavingCard(false);
      props.setPaymentStatus({...ps,...{message:`Payment failure: ${message}`,success: false, finished:true}})
    } finally {
      // props.setPaymentStatus({});
    }
  };




  const onMerchantScriptLoaded = () => {
    console.log('merchant script finished loaded');
    setMerchantScriptLoaded(true);
  };

  const onMerchantScriptFailed = () => {
    console.log('merchant script failed loading');
    setMerchantScriptLoading(false);
    setMerchantScriptFailedLoading(true);
  };

  useEffectAsync(async () => {
    if (props.cart && merchantLoaded && merchant) {
      setMerchantScriptLoading(true);
      setMerchantLoading(true);

      try {
        if (merchant === 'stripe') {
          console.log('publicKey: ', publicKey);
          console.log('clientId: ', clientId);
          loadStripe(
            publicKey,
            { stripeAccount: clientId },
          ).then(
            (stripePromise_) => {
              setStripePromise(stripePromise_);
              setMerchantScriptLoading(false);
              setMerchantLoading(false);
              onMerchantScriptLoaded();
            }
          ).catch(
            (error) => {
              console.log(error);
              onMerchantScriptFailed();
            }
          );
        }
      } catch (error) {
        const errorMessage = getErrorMessage(error);

        console.log('getMerchantPrivateKey error: ');
        console.log(errorMessage);

        toast.error(errorMessage);
        setMerchantScriptLoading(false);
        setMerchantLoading(false);
      }
    }
  }, [merchantLoaded, merchant]);

  useEffectAsync(async () => {

    if (props.cart) {
      console.log('TOTAL: ', props.cart.cart.total);
      setOrderTotal(props.cart.cart.total);
      setMerchantLoading(true);

      try {
        console.log('querying merchant and privatekey/token');
        console.log(props.cart.cart);
        const { data } = await API.getMerchantPrivateKey(props.cart.cart.accountId);

        
        console.log('merchant: ', data.merchant);
        console.log('privatekey: ', data.privatekey);
        console.log('token: ', data.token);
        console.log('publicKey: ', data.publicKey);
        console.log('clientId: ', data.clientId);
        // console.log('client_secret: ', data.client_secret);

        setMerchant(data.merchant);
        setPrivatekey(data.privatekey);
        setToken(data.token);
        setPublicKey(data.publicKey);
        setClientId(data.clientId);
        // setClientSecret(data.client_secret);
        
        createPaymentIntent(orderTotal);
        
        setMerchantLoading(false);
        setMerchantLoaded(true);
      } catch (error) {
        const errorMessage = getErrorMessage(error);

        console.log('getMerchantPrivateKey error: ');
        console.log(errorMessage);

        toast.error(errorMessage);
        setMerchantLoading(false);
      }
    }
  }, []);



  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <div className="d-flex flex-column justify-content-start align-items-start">
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            <div id="payment-button-parent">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => checkout(elements, stripe)}
                  disabled={transactionInProgress || merchantLoading}
                >
                  {`Charge Card`}
                </Button>
            </div>
            <div className="pt-2 w-100">
              <TransactionStatus />
            </div>
          </div>
        )}
      </ElementsConsumer>
    </Elements>
  );
};

CreditCardForm.propTypes = {
  cart: PropTypes.object.isRequired,
  resetPaymentStatus: PropTypes.func.isRequired,
  setCart: PropTypes.func.isRequired,
  setPaymentStatus: PropTypes.func.isRequired
};


const mapStateToProps = (state) => ({
  cart: cartSelectors.cartSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  resetPaymentStatus: () => dispatch(cartActions.resetPaymentStatus()),
  setCart: (cart) => dispatch(cartActions.setCart(cart)),
  setPaymentStatus: (payload) => dispatch(cartActions.setPaymentStatus(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);
