import { createReducer } from 'service/utility';

import { SET_NEW_OFFER, SET_OFFERS } from '../actions';
import { SIGN_OUT } from 'app/auth/redux/actions';

const initState = {};

export const newOffer = createReducer(initState, {
  [SET_NEW_OFFER]: (state, { newOffer: newOffer_ }) => newOffer_,
  [SIGN_OUT]: () => null,
});

export const offers = createReducer([], {
  [SET_OFFERS]: (state, { offers: offers_ }) => offers_,
  [SIGN_OUT]: () => null,
});
