const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const alpha2ToCountryMap = countries.getNames('en');

const countryPicklistValues = Object.keys(alpha2ToCountryMap).map((alpha2) => ({
  label: alpha2ToCountryMap[alpha2],
  value: alpha2,
}));

export const COUNTRY_MAP = alpha2ToCountryMap;
export const COUNTRY_PICKLIST_VALUES = countryPicklistValues;
