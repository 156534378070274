import React from 'react';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'common/formFields';

export const OfferTitle = ({offerModel, setOfferModel}) => {
  
  const handleInputChange = (field) => (event) => {
    offerModel = {...offerModel, [field]: event.target.value};
    // console.log('new offer from title', offerModel);
    setOfferModel(offerModel);
  };

  // TODO save offer to state on page change or something like that

  return (
    <React.Fragment>
    <Typography variant="h6">Offer Title</Typography>
    <br />
    <form className="offer-form">
      <div>
        <TextField label="Name of Offer" value={offerModel.name} onChange={handleInputChange('name')} size="small" 
          helpertext="This name should be unique to this offer." />
      </div>
      <div>
        <TextField label="Offer Description" value={offerModel.description} size="small" onChange={handleInputChange('description')}
          helpertext="This description will be visible to employees only."  />
      </div>
      <div>
        <TextField label="Product Offered" value={offerModel.product} size="small" onChange={handleInputChange('product')}
          helpertext="This is the name of the product that will be offered."  />
      </div>
      <div>
      <TextField label="Notes" value={offerModel.employeeNotes} size="small" multiline rows={3} onChange={handleInputChange('employeeNotes')}
      helpertext="This note will only show up to the employees of your business, and will not be seen by customer."  />
      </div>
    </form>
    </React.Fragment>
  )
}