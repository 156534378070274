import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { authActions } from 'app/auth';
import * as authService from 'service/firebase';
import * as NAVIGATION from 'config/navigation';


const SignOut_ = (props) => {
  const history = useHistory(); // not sure about this

  const handleSignOut = async () => {
    const success = await authService.signOut();
    if (success) {
      console.log('Successfully signed out');
      props.actions.signOut();
      history.push(NAVIGATION.LOGIN);
    } else {
      console.log('Could not sign out');
      toast('Could not sign out');
    }
  };

  return (
    <Button
      color="inherit"
      onClick={handleSignOut}
    >
      {'Sign Out'}
    </Button>
  );
};

SignOut_.propTypes = {
  actions: PropTypes.object.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    signOut: () => dispatch(authActions.signOut()),
  },
});

export const SignOut = connect(mapStateToProps, mapDispatchToProps)(SignOut_);
