import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { TextField, MenuItem } from '@material-ui/core';
import { isRequired } from 'service/utility/errorMessages';

export class PicklistField extends Component {
  static propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    picklistValues: PropTypes.array.isRequired,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    error: '',
    fullWidth: false,
    label: '',
    required: false,
    value: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue === null ? '' : rawValue;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  };

  componentWillUnmount = () => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  };

  validateSelf = (value) => {
    const { required, label } = this.props;

    if ((value === '' || value === null) && required) return isRequired(label);

    return null;
  };

  handleLeaveFocus = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue === null ? '' : rawValue;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (value) => {
    const { onChange } = this.props;
    const error = this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const { className, error, fullWidth, onChange, label, picklistValues, required, value: rawValue, ...rest } = this.props;
    const value = rawValue === null ? '' : rawValue;
    const { blurred } = this.state;

    return (
      <TextField
        select
        error={blurred && Boolean(error)}
        helperText={error}
        required={required}
        fullWidth={fullWidth}
        label={label}
        value={value}
        onChange={(e) => this.handleChangeSelf(e.target.value)}
        onBlur={this.handleLeaveFocus}
        variant="outlined"
        classes={{ root: `text-field picklist-field${className ? ` ${className}` : ''}` }}
        InputLabelProps={{
          shrink: true,
        }}
        margin="dense"
        {...rest}
      >
        {picklistValues.map((picklistValue) => (
          <MenuItem
            key={picklistValue.value}
            value={picklistValue.value}
          >
            {picklistValue.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}
