import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, SelectableInput } from 'common/formFields';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Grid from '@material-ui/core/Grid';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker,
} from '@material-ui/pickers';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
  caption: {
    marginBottom: '1rem',
    marginLeft: '2rem',
    marginTop: '-1rem',
  },
  radioFormGroup: {
    marginLeft: '2rem',
    marginBottom: '1rem'
  },
  radioLabel: {
    marginTop: '1rem'
  }
}));

export const FixedAdParams = ({offerModel,setOfferModel}) => {
  const classes = useStyles();

  const [checkMon, setCheckMon] = React.useState(false);
  const [checkTue, setCheckTue] = React.useState(false);
  const [checkWed, setCheckWed] = React.useState(false);
  const [checkThu, setCheckThu] = React.useState(false);
  const [checkFri, setCheckFri] = React.useState(false);
  const [checkSat, setCheckSat] = React.useState(false);
  const [checkSun, setCheckSun] = React.useState(false);
  const [displaySalePrice,setDisplaySalePrice] = React.useState(null);

  const handleInputChange = (field) => (event) => {
    if(field === 'salePrice') {
      const v = event.target.value * 100;
      offerModel = {...offerModel, [field]: v};
    } else {
      offerModel = {...offerModel, [field]: event.target.value};
    }
    setOfferModel(offerModel);
  };

  React.useEffect(() => {
    if(offerModel.salePrice && offerModel.daysEnabled) {
      const dsp = offerModel.salePrice / 100;
      setDisplaySalePrice(dsp);
      
      const setters = {
        1: setCheckMon,2: setCheckTue,3: setCheckWed,4: setCheckThu,5: setCheckFri,6: setCheckSat,0: setCheckSun
      }
      const days = offerModel.daysEnabled.split(',');
      days.forEach(day => {
        console.log({day});
        const setter = setters[day];
        setter(true);
      })
    }
    if(offerModel.startDate) {
      
    }

  }, [offerModel]);


  const handleCheckboxChange = (field) => (event) => {
    offerModel = {...offerModel, [field]: event.target.checked};
    setOfferModel(offerModel);
  }

  const handleStartTimeChange = (date) => {
    offerModel = {...offerModel, startTime: date};
    setOfferModel(offerModel);
  }
  const handleEndTimeChange = (date) => {
    offerModel = {...offerModel, endTime: date};
    setOfferModel(offerModel);
  }
  const handleStartDateChange = (date) => {
    offerModel = {...offerModel, startDate: date};
    setOfferModel(offerModel);
  }
  const handleEndDateChange = (date) => {
    offerModel = {...offerModel, endDate: date};
    setOfferModel(offerModel);
  }

  const handleDaysChange = (ev) => {
    const setters = {
      0: setCheckSun,1: setCheckMon,2: setCheckTue,3: setCheckWed,4: setCheckThu,5: setCheckFri,6: setCheckSat,
    }
    const day = ev.target.value;
    let checkedDays = offerModel.daysEnabled ? offerModel.daysEnabled.split(',') : [];
    if(checkedDays.includes(day)) {
      // uncheck day
      checkedDays.splice(checkedDays.indexOf(day),1);
      setters[day](false);
    } else {
      checkedDays.push(day);
      setters[day](true);
    }
    setOfferModel({...offerModel, daysEnabled: checkedDays.join(',')});
    
  }

  return (
    <React.Fragment>
    <Typography variant="h6">Fixed Ad</Typography>
    <form className="offer-form">
    <div>
      <Grid container spacing={3}>
            <Grid item>
              {/* <TextField label="Start Date" value={offerModel.startDate} type="date" 
                onChange={handleInputChange('startDate')} size="small" 
                helpertext={`Ad will start to appear on ${offerModel.startDate}`} /> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Start Date"
                  value={offerModel.startDate}
                  onChange={handleStartDateChange}
                  animateYearScrolling
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  />
                </MuiPickersUtilsProvider>
            </Grid>

        <Grid item>
            {/* <TextField label="End Date" value={offerModel.endDate} type="date" 
              onChange={handleInputChange('endDate')} size="small" 
              helpertext={`Ad will stop appearing on ${offerModel.endDate}`} /> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="End Date"
                value={offerModel.endDate}
                onChange={handleEndDateChange}
                animateYearScrolling
                inputVariant="outlined"
                format="MM/dd/yyyy"
                />
              </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      </div>
      <div className={classes.root}>
        <div className="form-control-label">Times</div>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Start Time"
              value={offerModel.startTime}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputVariant="outlined"
              minutesStep={5}
              onChange={handleStartTimeChange}
            />
            </MuiPickersUtilsProvider>
              {/* <TimeField label="Start time" value={offerModel.startDate} type="time"
               inputProps={{step:300}}
              onChange={handleInputChange('startTime')} size="small" /> */}
            </Grid>
            <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="End Time"
              value={offerModel.endTime}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputVariant="outlined"
              minutesStep={5}
              onChange={handleEndTimeChange}
            />
            </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        <Typography variant="caption" className={classes.caption}>These are the times at which the ad could be displayed</Typography>
      </div>
      <div>
          <Typography>Week Days</Typography>
        <Grid container spacing={1}>
          <Grid item xs>
            <FormControlLabel control={ <Checkbox value="0" checked={checkSun}
            onChange={handleDaysChange} /> } label="Sun" />
          </Grid>
          <Grid item xs>
            <FormControlLabel control={ <Checkbox value="1" checked={checkMon}
            onChange={handleDaysChange}/> } label="Mon" />
          </Grid>
          <Grid item xs>
            <FormControlLabel control={ <Checkbox value="2" checked={checkTue}
            onChange={handleDaysChange}/> } label="Tue" />
          </Grid>
          <Grid item xs>
            <FormControlLabel control={ <Checkbox value="3" checked={checkWed}
            onChange={handleDaysChange}/> } label="Wed" />
          </Grid>
          <Grid item xs>
            <FormControlLabel control={ <Checkbox value="4" checked={checkThu}
            onChange={handleDaysChange}/> } label="Thu" />
          </Grid>
          <Grid item xs>
            <FormControlLabel control={ <Checkbox value="5" checked={checkFri}
            onChange={handleDaysChange}/> } label="Fri" />
          </Grid>
          <Grid item xs>
            <FormControlLabel control={ <Checkbox value="6" checked={checkSat}
            onChange={handleDaysChange}/> } label="Sat" />
          </Grid>
        </Grid>
        <Typography variant="caption">Ad will appear only on the checked days</Typography>
      </div>
      <div>
        <TextField label="Number of Offers" value={offerModel.totalAvailable}
          onChange={handleInputChange('totalAvailable')} size="small" 
          helpertext={`Only ${offerModel.totalAvailable} offers will be issued every time is triggered.`}/>
      </div>
      <div>
        <TextField label="Sale Price" value={displaySalePrice}
          size="small" onChange={handleInputChange('salePrice')} 
          helpertext={`Please note that the sale price must be $5 or more`}/>
      </div>
      <div>
        <TextField label="Tax" value={offerModel.tax}
          size="small" onChange={handleInputChange('tax')} 
          helpertext={``}/>
      </div>
    </form>
    </React.Fragment>
  )
}