import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
// import { Auth } from 'aws-amplify';

import { authActions } from 'app/auth';
import { cartActions } from 'app/cart';
import { useEffectAsync } from 'service/utility';
// import * as authService from 'service/firebase';

import * as firebase from 'firebase/app';
import 'firebase/auth';

const Initializer_ = (props) => {
  const [loading, setLoading] = React.useState(true);

  // useEffectAsync(async () => {
  //   setLoading(true);
  //   try {
  //     const response = await firebase.onAuthStateChanged();

  //     console.log(response);
  //     const { user } = response;

  //     // console.log('authenticated user: ', user);
  //     props.signIn(user);
  //     setLoading(false);
  //     // history push -> profile
  //   } catch (error) {
  //     console.log('No authenticated user');
  //     props.signOut();
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  const onFirebaseAuthStateChanged = (user) => {
    setLoading(false);
    if (user) {
      // User is signed in.
      // setIsUserAuthenticated(true);
      props.signIn(user);
    } else {
      console.log('no user')
    }
  };

  React.useEffect(() => {
    const unsubscribe = {};

    unsubscribe.firebase = firebase.auth().onAuthStateChanged(onFirebaseAuthStateChanged);

    return () => {
      if (unsubscribe.firebase) unsubscribe.firebase();
    };
  }, [props.navigation])  



  if (loading) return null;

  return props.children;
};

Initializer_.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array,
  ]),
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};


const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  signIn: (user) => dispatch(authActions.signIn(user)),
  signOut: () => dispatch(authActions.signOut()),
  setCart: (cart) => dispatch(cartActions.setCart(cart)),
});

export const Initializer = connect(mapStateToProps, mapDispatchToProps)(Initializer_);
