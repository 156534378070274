import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';

import { authSelectors } from 'app/auth';
import { appSelectors } from 'app/redux';
import { Typography, Button } from '@material-ui/core';
import { NameField } from 'common/formFields';
import ProfileView from './ProfileView';
import ProfileEdit from './ProfileEdit';
import { Spinner } from 'common/statusIndicators';

const Profile_ = (props) => {
  
  useEffect(() => {
    if(props.account && props.location) setLoading(false);
  }, [props.account,props.location]);

  const [loading, setLoading] = React.useState(true);

  // const handleSubmit = () => (null);

  return (
    <React.Fragment>  
      <Typography variant="h4" classes={{ h4: 'header-text' }} >
        {'Contact'}
      </Typography>
  { loading ? (<Spinner />) : (<ProfileView account={props.account} location={props.location} />) }
    </React.Fragment>
  );
};

Profile_.propTypes = {
  user: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  user: authSelectors.authUser(state),
  account: appSelectors.account(state),
  location: appSelectors.location(state)
});

const Profile = connect(mapStateToProps)(Profile_);

export default Profile;
