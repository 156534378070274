import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  rightSide: {
    textAlign: 'right',
    fontSize: '1em'
  },
  leftSide: {
    fontWeight: '500',
    fontSize: '1em'
  },
  imgBackdrop: {
    padding: '1rem',
    background: 'rgba(0,0,0,0.1)',
    minHeight: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imgElement: {
    width: '400px'
  },
  noValue: {
    color: '#999999',
    fontSize: '0.9em'
  }
}));

export const Review = ({offerModel}) => {
  const classes = useStyles();
  const fields = ['type','name', 'minOccupancy', 'timeBelowOccupancy', 'startDate', 'endDate', 
    'totalAvailable', 'salePrice', ];  
  const fieldData = {
    'type': 'Ad Type',
    'name': 'Offer Name',
    'minOccupancy': 'Occupancy Parameters',
    'timeBelowOccupancy': 'Time of Below Occupancy',
    'startDate': 'Starting Date',
    'endDate': 'Ending Date',
    'startTime': 'Starting Time',
    'endTime': 'Ending Time',
    'totalAvailable': 'Number of Offers',
    'fullPrice': 'Price of Item',
    'percentOff': 'Percent Off',
    'salePrice': 'Sale Price',
  };
  const fieldLabel = (field) => {
    return fieldData[field];
  } 
  const fieldValue = (field) => {
    const rawValue = offerModel[field];
    if(!rawValue) return<div className={classes.noValue}>not set</div>;
    if(['name','totalAvailable'].includes(field)) return rawValue;
    let prettyValue = '';
    if(field === 'type') {
      prettyValue = (rawValue === 'fixed') ? 'Fixed Ad' : 'Capacity-Based Ad';
    }
    if(field === 'minOccupancy') {
      prettyValue = `must be at ${rawValue}% or below`;
    }
    if(field === 'timeBelowOccupancy') {
      prettyValue = `${rawValue} minutes or longer`;
    }
    if(field === 'salePrice') {
      prettyValue = '$' + (rawValue / 100);
    }
    if(['startDate','endDate'].includes(field)) prettyValue = moment(rawValue).format('MM/DD/YYYY');
    if(['startTime','endTime'].includes(field)) prettyValue = moment(rawValue).format('h:mm a');
    if(field == 'percentOff') prettyValue = `${rawValue}%`;
    return prettyValue;
  }
  const gridItem = (field) => {
    if(offerModel.type === 'fixed' && ['minOccupancy','timeBelowOccupancy'].includes(field)) return null;
    return (
    <Grid container spacing={4} direction="row" justify="center" alignItems="center" key={field}>
      <Grid item xs={6}><Typography className={classes.rightSide}>{`${fieldLabel(field)}:`}</Typography></Grid>
      <Grid item xs={6}><Typography className={classes.leftSide}>{fieldValue(field)}</Typography></Grid>
    </Grid>
    )
  }
  

  return (
    <React.Fragment>
    <Typography variant="h6">Review and Finalize</Typography>
    <form className="offer-form">
      <Typography>Ad Details</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {fields.map((key) => (
            gridItem(key)
          ))}
        </Grid>
        <Grid item xs={6}>
          <div className={classes.imgBackdrop}>
            <img src={offerModel.imageURL} alt="Fetching ad image" className={classes.imgElement}/>
          </div>
        </Grid>
      </Grid>
    </form>
    </React.Fragment>
  )
}