import React, {useEffect} from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { cartActions, cartSelectors } from 'app/cart';
import { appSelectors } from 'app/redux';
import { BackButton } from 'common/buttons';
import { CartTotals, LocationInformation, CartItems, CartOffer } from 'common/cart';
import * as NAVIGATION from 'config/navigation';
import { getOffer } from 'service/api';
import { useEffectAsync, getErrorMessage } from 'service/utility';
import { Spinner } from 'common/statusIndicators';
// import { StandardTextField, TextField, PasswordField, EmailField, PhoneField } from 'common/formFields';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      marginTop: '-0.2rem'
    },
    '& .MuiInput-formControl': {
      margin: '0.75rem 0'
    }
  },
  textField: {
    margin: '1rem 0'
  },
  topbarLogo: {
    height: '40px'
  }
}));

const Cart = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const defGoToCheckout = () => history.push(NAVIGATION.CHECKOUT);
  const [ loading, setLoading] = React.useState(true);
  const [ termsAgreed, setTermsAgreed] = React.useState(false);
  const [ offer, setOffer] = React.useState();
  const [ location, setLocation] = React.useState();
  const [ name, setName] =React.useState('');
  const [ email, setEmail] = React.useState('');
  const [ phone, setPhone] = React.useState('');
  const [validationErrors, setValidationErrors] = React.useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const accountId = urlParams.get('accountId');
  const locationId = urlParams.get('locationId');
  const offerId = urlParams.get('offerId');

  useEffect(() => {
    props.setPaymentStatus(null);
  },[]);

  useEffectAsync(async () => {
    try {
      const { data } = await getOffer(accountId,locationId,offerId);
      // console.log(data);
      setLoading(true);
      if(data) { 
        const {offer, location} = data;
        setOffer(offer);
        setLocation(location);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(getErrorMessage(error));
    } 
  },[]);

  // redirect if offer doesnt exist
  const goToCheckout = () => {
    const cart = {
      termsAgreed: termsAgreed,
      customer: { name, email, phone },
      offer: offer,
      total: offer.salePrice,
      location: location,
      locationId: location.id,
      accountId: accountId
    }
    // console.log(cart);
    props.setCart(cart);

    defGoToCheckout();
  }
  const onTermsAndConditionsAgreed = (agreed) => {
    // console.log('are the terms agreed? ', agreed);
    setTermsAgreed(agreed);

    // props.setCart(cart);
  };
  const handleChange = (varName, setStateFunc) => (update) => {
    let isValid = update.target.checkValidity();
    const value = update.target.value;
    if(varName === 'phone') {
      const regex = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
      // console.log(regex.test(value));
      isValid = regex.test(value)
    }
    // console.log(`${varName} is ${isValid ? 'valid':'invalid'}`);
    setStateFunc(value);

    setValidationErrors((prevValidationErrors) => ({
      ...prevValidationErrors,
      [varName]: !isValid,
    }));
  };


  const canCheckout = () => {
    if(!termsAgreed) return false;
    if(validationErrors.name) return false;
    if(validationErrors.email) return false;
    if(validationErrors.phone) return false;
    if(!name) return false;
    if(!phone) return false;
    if(!email) return false;
    return true;
  }
  // const handleInputChange = (field) => (event) => {
  //   newOffer = {...newOffer, [field]: event.target.value};
    // console.log('new offer from title', newOffer);
  //   setNewOffer(newOffer);
  // };
  return (
    <React.Fragment>
      <div className="container pt-3 pb-3 mb-6">
        <h1 className="text-center"><img className={classes.topbarLogo} src={require('assets/pc-logo-wb.svg')} alt="PeopleCount" /></h1>
        <div className="row">
          <div className="col-md-6 d-flex flex-column">
            <div className="pt-4 w-100">
              <div className="d-flex flex-column justify-content-start align-items-start">
                <span className="font-weight-thick text-small">
                  {'Offer'}
                </span>
                <div className="border rounded w-100 p-3">
                  <div className="w-100 d-flex flex-column justify-content-start align-items-start">
                    {offer && (
                      <React.Fragment>
                        <span>
                          {offer?.name}
                        </span>
                        <span>
                          {offer?.description}
                        </span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <span className="font-weight-thick text-small">
                  {'Location'}
                </span>
                <div className="border rounded w-100 p-3">
                  <div className="w-100 d-flex flex-column justify-content-start align-items-start">
                    {location && (
                      <React.Fragment>
                        <span>
                          {location?.name}
                        </span>
                        <span>
                          {location?.address || 'No Address Information.'}
                        </span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-4 w-100">
                <span className="font-weight-thick text-small">
                  {'Product'}
                </span>
                {offer && <CartOffer
                  loading={loading}
                  item={offer}
                  termsAgreed={termsAgreed}
                  onTermsAndConditionsAgreed={onTermsAndConditionsAgreed}
                /> }
              </div>
            </div>
          </div>
          <div className="col-md-6 pt-4 d-flex flex-column">
          <   span className="font-weight-thick text-small">
                  {'Customer'}
                </span>
            <form noValidate 
              className="d-flex border p-3 flex-column justify-content-start align-items-start">
              <fieldset>
                <TextField
                  label="Name"
                  value={name}
                  error={validationErrors.name}
                  onChange={handleChange('name', setName)}
                  required
                  fullWidth
                  className={classes.root}
                  helperText={validationErrors.name && 'Full Name is required'}
                />
              <TextField
                label="Email"
                value={email}
                error={validationErrors.email}
                onChange={handleChange('email', setEmail)}
                required
                fullWidth
                type="email"
                className={classes.root}
                helperText={validationErrors.email && 'Please enter a valid email address'}
              />
              <TextField
                label="Phone number"
                value={phone}
                error={validationErrors.phone}
                onChange={handleChange('phone', setPhone)}
                required
                fullWidth
                helperText={validationErrors.phone && 'Please enter a valid phone number'}
                className={classes.root}
              />
              </fieldset>
            </form>
            <div className="pt-4 w-100 d-flex flex-row justify-content-end">
              <Button
                color="primary"
                variant="contained"
                onClick={goToCheckout}
                disabled={!canCheckout()}
              >
                {'Checkout Now'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Cart.propTypes = {
  setCart: PropTypes.func,
  setPaymentStatus: PropTypes.func
};


const mapDispatchToProps = (dispatch) => ({
  setCart: (cart) => dispatch(cartActions.setCart(cart)),
  setPaymentStatus: (ps) => dispatch(cartActions.setPaymentStatus(ps))
});


export default connect(null, mapDispatchToProps)(Cart);
