import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';

export class ButtonSpinner extends Component {
  render() {
    return (
      <div className="button-spinner-container">
        <CircularProgress
          size={24}
          color="inherit"
        />
      </div>
    );
  }
}
