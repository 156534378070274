import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { FormControl, FormHelperText, InputLabel, List } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { components } from 'react-select';
import Async from 'react-select/async';
import { isRequired } from 'service/utility/errorMessages';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Search />
  </components.DropdownIndicator>
);

const MenuList = (props) => (
  <components.MenuList {...props}>
    <List classes={{ root: 'no-padding' }}>
      {props.children}
    </List>
  </components.MenuList>
);

MenuList.propTypes = {
  children: PropTypes.node,
};

export class GenericSearch extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    cacheOptions: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    getOptionLabel: PropTypes.func.isRequired,
    getOptionValue: PropTypes.func.isRequired,
    getOptions: PropTypes.func.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    suggestionItem: PropTypes.func.isRequired,
    validate: PropTypes.func,
    value: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    autoFocus: false,
    cacheOptions: true,
    disabled: false,
    error: '',
    fullWidth: true,
    required: false,
  };


  constructor(props) {
    super(props);

    const { value, onChange, getOptionValue } = this.props;
    const optionValue = getOptionValue(value || {});
    const error = this.validateSelf(optionValue);

    this.state = {
      blurred: false,
    };

    if (onChange) {
      onChange({ value, error });
    }
  }

  validateSelf = (value) => {
    const { required, label, validate } = this.props;

    if (!value && required) return isRequired(label);
    if (validate) return validate(value);

    return null;
  };

  handleLeaveFocus = () => {
    const { value, onChange, getOptionValue } = this.props;
    const optionValue = getOptionValue(value || {});
    const error = this.validateSelf(optionValue);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (value) => {
    const { onChange, getOptionValue } = this.props;
    const optionValue = getOptionValue(value || {});
    const error = this.validateSelf(optionValue);

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const { autoFocus, cacheOptions, disabled, error, label, value, suggestionItem: SuggestionItem, fullWidth, className } = this.props;
    const { blurred } = this.state;
    const showAsError = blurred && Boolean(error);

    return (
      <FormControl
        classes={{ root: `generic-search${className ? ` ${className}` : ''}` }}
        margin="dense"
        fullWidth={fullWidth}
      >
        {label && (
          <InputLabel
            error={showAsError}
            classes={{ root: 'input-label' }}
            shrink
            variant="outlined"
          >
            {label}
          </InputLabel>
        )}
        <Async
          cacheOptions={cacheOptions}
          isClearable
          isDisabled={disabled}
          value={value}
          getOptionValue={this.props.getOptionValue}
          getOptionLabel={this.props.getOptionLabel}
          loadOptions={this.props.getOptions}
          onChange={this.handleChangeSelf}
          onBlur={this.handleLeaveFocus}
          placeholder=""
          className={classnames('react-select-container', { error: showAsError })}
          classNamePrefix="react-select"
          components={{ Option: SuggestionItem, DropdownIndicator, MenuList }}
          maxMenuHeight={312}
          openMenuOnClick={false}
          autoFocus={autoFocus}
          menuPortalTarget={document.body}
          menuPosition="absolute"
          menuPlacement="bottom"
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
        {error && (
          <FormHelperText
            error={showAsError}
            variant="outlined"
            margin="dense"
            required
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}
