import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

export class StatusOverlay extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.array,
    ]),
  };

  render() {
    return (
      <div className="status-overlay">
        {this.props.children}
      </div>
    );
  }
}
