import React from 'react';
// import { TextField as MUITextField } from '@material-ui/core';
import { FormControl, InputLabel, Input, FormHelperText, Typography, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  labelRoot: {
    position: 'relative',
  },
  labelShrink: {
    transform: 'none !important'
  },
  inputRoot: {
    marginLeft: '3rem',
    border: '1px solid',
    borderRadius: '5px',
  },
  inputInput: {
    padding: '5px 15px'
  },
  helperTextRoot: {
    marginLeft: '3.2rem',
    color: '#999999'
  },
  root: {
    marginBottom: '1rem'
  }
});

export const TextField = (props) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.margin}>
      <InputLabel shrink={true} classes={{ root: classes.labelRoot, shrink: classes.labelShrink }}>
        {props.label}
      </InputLabel>
      <Input {...props} disableUnderline={true} classes= {{root: classes.inputRoot, input: classes.inputInput}} />
      <Typography variant="caption" classes={{root: classes.helperTextRoot}}>{props.helpertext}</Typography>
    </FormControl>
  );
}
// export const TextField = (props) => {
//   const classes = useStyles();
//   return (
//     <MUITextField
//       {...props}
//       InputProps={{disableUnderline: true, classes: {root: classes.inputRoot, input: classes.inputInput} }}
//       FormHelperTextProps={{classes: {root: classes.helperTextRoot}}}
//       classes={{root: classes.root}}
//       InputLabelProps={{
//         shrink: true,
//         classes: { root: classes.labelRoot, shrink: classes.labelShrink }
//       }}
//     />
//   );
// }
