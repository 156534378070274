import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';

import { cartSelectors } from 'app/cart';
import { Spinner, FailureBadge, SuccessBadge } from 'common/statusIndicators';


const TransactionStatus = (props) => {
  const { paymentStatus: { started, finished, success, message, actions } } = props;

  if (!started) return null;

  return (
    <div className="w-100 d-flex flex-column justify-content-center align-items-center">
      {!finished && (
        <Spinner size={48} />
      )}
      {finished === null && actions && actions.retry && (
        <Button
          onClick={actions.retry}
          color="secondary"
          variant="contained"
        >
          {'Retry Polling'}
        </Button>
      )}
      {finished && success && (
        <SuccessBadge />
      )}
      {finished && !success && (
        <FailureBadge />
      )}
      {message && (
        <span className="mb-3">
          {message}
        </span>
      )}
    </div>
  );
};

TransactionStatus.propTypes = {
  paymentStatus: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  paymentStatus: cartSelectors.paymentStatusSelector(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionStatus);
