import { toast, Slide } from 'react-toastify';

const TOAST_CONFIG = {
  autoClose: false,
  draggable: false,
  type: toast.TYPE.INFO,
  position: toast.POSITION.BOTTOM_CENTER,
  transition: Slide,
  hideProgressBar: true,
};

export default TOAST_CONFIG;
