import React from 'react';
import { PropTypes } from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Link as ButtonLink } from '@material-ui/core';

const TermsAndConditionsDialog = (props) => {
  const termsConditionsHTML = props.termsAndConditions.replace(/\n/g, '<br />');

  return (
    <Dialog
      open
      onClose={props.onDisagree}
    >
      <DialogTitle>
        {'TERMS AND CONDITIONS'}
      </DialogTitle>
      <DialogContent classes={{ root: 'd-flex flex-column justify-content-start align-items-start' }}>
        <span dangerouslySetInnerHTML={{ __html: termsConditionsHTML }} />
      </DialogContent>
      <DialogActions>
        <ButtonLink
          component="button"
          onClick={props.onDisagree}
        >
          {'Disagree'}
        </ButtonLink>
        <Button
          color="primary"
          variant="contained"
          onClick={props.onAgree}
        >
          {'Agree'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TermsAndConditionsDialog.propTypes = {
  onAgree: PropTypes.func.isRequired,
  onDisagree: PropTypes.func.isRequired,
  termsAndConditions: PropTypes.string.isRequired,
};


export default TermsAndConditionsDialog;
