import { createReducer } from 'service/utility';

import { SET_CURRENT_USER, SIGN_OUT } from '../actions';

const initState = null;

export const user = createReducer(initState, {
  [SET_CURRENT_USER]: (state, { user: user_ }) => user_,
  [SIGN_OUT]: () => null,
});
