import React from 'react';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'common/formFields';

export const Terms = ({offerModel, setOfferModel}) => {
  const [termsText, setTermsText] = React.useState(''); 

  const handleInputChange = () => (event) => {
    const newNewOffer = {...offerModel, terms: event.target.value};
    console.log(event.target.value,newNewOffer);
    setOfferModel(newNewOffer);
  };

  return (
    <React.Fragment>
    <Typography variant="h6">Terms and Conditions</Typography>
    <form className="offer-form">
      <TextField label="Terms and Conditions" multiline rows={4} value={offerModel.terms}
        helpertext="These are the terms that the customer will need to accept and agree to before they can add an offer to the cart" 
        onChange={handleInputChange()}/>
    </form>
    </React.Fragment>
  )
}