// import * as otherSagas from './cartSagas';
import { loadCartSaga } from './loadCartSaga';

export * from './cartSagas';
export {
  loadCartSaga,
};

export const _middleware = [
  loadCartSaga,
];
