import { authSagas } from 'app/auth';

const sagasList = [
  authSagas,
];

export const initSagas = (sagaMiddleware) => {
  sagasList.forEach((sagas) => {
    Object.values(sagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
  });
};
